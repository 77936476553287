




import { useHistory } from 'react-router-dom';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ShareIcon from '@mui/icons-material/Share';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import JoditEditor, { Jodit } from 'jodit-react';
import Loader from '../utils/loader';
import ReactSelect from "react-select";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import mammoth from "mammoth";
import { Modal as Modal1, Form } from "react-bootstrap";
import { getopportunities, getUserInfo } from "../services/opportunityService";
import jsPDF from 'jspdf';
import '../css/opportunity_Screen_CSS/OppourtunityManagementTemplate.css'
import { ContentState, Editor, EditorState, Modifier, RichUtils, SelectionState, convertToRaw, convertFromHTML } from 'draft-js';
import axios from "axios"
import styled from "styled-components";
import React, { useRef } from "react";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import InputLabel from '@mui/material/InputLabel';
import {
    Container,
    TextField,
    Button,
    IconButton,
    Modal,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Grid,
    Menu,
    MenuItem,
    FormControl,
    FormControlLabel,
    Checkbox, Box
} from "@material-ui/core";
import WindowIcon from "@mui/icons-material/Window";
import RefreshIcon from "@mui/icons-material/Refresh";
import FolderIcon from "@mui/icons-material/Folder";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DownloadIcon from "@mui/icons-material/Download";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import AddIcon from "@mui/icons-material/Add";
import TableRowsIcon from "@mui/icons-material/TableRows";
import { useState, useEffect } from "react";
import { DocumentGridCard, DocumentListCard } from "./MyDocumentsCards";
import { toast } from "react-toastify";
import { showToast } from '../messages';
import templatesService, { gettemplates, downloadTemplates, getFileContent, DeleteTemplate, UploadTemplate, getTemplates, TemplateMerge, copyTemplate, getSectionDocument } from '../services/templatesService';
import { PreviewEditor } from './PreviewEditor';
import PreviewIcon from '@mui/icons-material/Preview';
import EditNoteIcon from '@mui/icons-material/EditNote';
import organizationService from "../services/organizationService";
import { addAssetsection, getAssetsection } from "../services/assetSectionServices";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { Content } from 'antd/es/layout/layout';
import { width } from '@material-ui/system';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useDispatch, useSelector } from "react-redux";
import ImageIcon from '@mui/icons-material/Image';
// import { PathType } from 'html2canvas/dist/types/render/path';
import html2pdf from 'html2pdf.js';
import { saveAs } from 'file-saver';
import { asBlob } from 'html-docx-js-typescript';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import SingleTemplate from './Cards/SingleTemplate';
import { aiGeneratedRequirnments, extractPdfData, getCoverPages, getS3Data, improveInput, improveSectionContent, OrganizeSection } from '../services/s3bucketService';
import { addTemplateTheme, getasset } from '../services/assetService';
import { ResourceLinksModal } from './pages/Modals/templateModal';
import Pagination from '@mui/material/Pagination';
import { EditTemplateModal } from './pages/Modals/EditTemplateModal';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'blue',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);

export default function OppourtunityManagementTemplate() {
    const [listView, setListView] = useState(false);
    const [userdata, setuserdata] = useState(null);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [checkedBox, setCheckedBox] = useState(null);
    const [editorState, setEditorState] = useState(null);
    const [templateData, setTemplateData] = useState([])
    const [templateName, setTemplateName] = useState(null)
    const [proceedClicked, setProceedClicked] = useState(false);
    const [loading, setLoading] = useState(false)
    const [content, setContent] = useState(null);
    const [checkedTempName, setCheckedTempName] = useState(null);
    const [checkedTitle, setCheckedTitle] = useState(null);
    const [fileURL, setFileURL] = useState()
    const [showModal, setShowModal] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)
    const [previewContent, setPreviewContent] = useState()
    const editor = useRef(null)
    const [inputKey, setInputKey] = useState(0);
    const [fileType, setFileType] = useState('');
    const [open, setOpen] = useState(false);
    const [templateToDelete, setTemplateToDelete] = useState(null);
    const [htmlContent, setHtmlContent] = useState(null)
    const [idForFile, setIdForFile] = useState(null)
    const [cuurentUser, setCuurentUser] = useState('')
    const [userInfo, setUserInfo] = useState('')
    const [OrgName, setOrgName] = useState(null)
    const [asssetsectionData, setAssetSectionData] = useState(null)
    const [dataArray, setDataArray] = useState([]);
    const [assetType, setAssetType] = useState(null)
    const [openAddModal, setOpenAddModal] = useState(false)
    const [title, setTitle] = useState('');
    const [adddescription, setaddDescription] = useState('');
    const [showProposalCreation, setShowProposalCreation] = useState(false)
    const [popupForm, setPopupForm] = useState(false)
    const [editPopup, setEditPopup] = useState(false);

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [path, setPath] = useState(null)
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({});
    const [contentForScan, setContentForScan] = useState(null)
    const [dateValues, setDateValues] = useState({}); // Object to store selected dates
    const [uniqueLabels, setUniqueLabels] = useState({}); // Map for unique labels
    const [isAscending, setIsAscending] = useState(true);

    const [signatureValue, setSignatureValue] = useState()
    const [fieldsToRender, setFieldsToRender] = useState([]);
    const [logoValue, setLogoValue] = useState()
    const [dataType, setDataType] = React.useState('');
    const [dataTypeMap, setDataTypeMap] = useState({});
    const [duplicateFileName, setDuplicateFileName] = useState('')
    const [isDuplicateModal, setShowDuplicateModal] = useState()
    const [existingFileName, setExistingFileName] = useState('')
    const [existingCopyDocumentPath, setExistingCopyDocumentPath] = useState()
    const [fileData, setFileData] = useState()
    const [copyButtonValidation, setCopyButtonValidation] = useState()
    const [templateReduxData, setTemplateReduxData] = useState('')
    const [typeOfAsset, setTypeOfAsset] = useState(null);
    const [AssetId, setAssetId] = useState(null)
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [oldFileName, setOldFileName] = useState("")
    const [wordCount, setWordCount] = useState(0)
    const [placeholder, setPlaceholder] = useState(null)
    const history = useHistory()
    const assetid = sessionStorage.getItem('assetId');
    const orgId = sessionStorage.getItem("organizationId")
    const opportunityId = sessionStorage.getItem("opportunityId")
    const templateDatareduxvalues = useSelector(state => state.template)
    const rfmManagementData = useSelector(state => state.rfp)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth < 768);
    const [selectedSectors, setSelectedSectors] = useState(null)
    const [selectedFields, setSelectedFields] = useState([])
    const [ispdfFile, setIspdfFile] = useState()
    const sectorData = [
        { label: 'JUSTICE, DEPARTMENT OF', value: 'JUSTICE, DEPARTMENT OF' },
        { label: 'DEPT OF DEFENSE', value: 'DEPT OF DEFENSE' },
        { label: 'VETERANS AFFAIRS, DEPARTMENT OF', value: 'VETERANS AFFAIRS, DEPARTMENT OF' },
        { label: 'AGRICULTURE, DEPARTMENT OF', value: 'AGRICULTURE, DEPARTMENT OF' },
        { label: 'COMMERCE, DEPARTMENT OF', value: 'COMMERCE, DEPARTMENT OF' },
        { label: 'NATIONAL AERONAUTICS AND SPACE ADMINISTRATIONg', value: 'NATIONAL AERONAUTICS AND SPACE ADMINISTRATION' },
        { label: 'TREASURY, DEPARTMENT OF THE', value: 'TREASURY, DEPARTMENT OF THE' },
        { label: 'SOCIAL SECURITY ADMINISTRATION', value: 'EneSOCIAL SECURITY ADMINISTRATIONrgy' },
        { label: 'GENERAL SERVICES ADMINISTRATION', value: 'GENERAL SERVICES ADMINISTRATION' },
        { label: 'AGENCY FOR INTERNATIONAL DEVELOPMENT', value: 'AGENCY FOR INTERNATIONAL DEVELOPMENT' },
        { label: 'ENVIRONMENTAL PROTECTION AGENCY', value: 'ENVIRONMENTAL PROTECTION AGENCY' },
        { label: 'HOMELAND SECURITY, DEPARTMENT OF', value: 'HOMELAND SECURITY, DEPARTMENT OF' },
        { label: 'SMITHSONIAN INSTITUTION', value: 'SMITHSONIAN INSTITUTION' },
        { label: 'ENERGY, DEPARTMENT OF', value: 'ENERGY, DEPARTMENT OF' },
        { label: 'NATIONAL ARCHIVES AND RECORDS ADMINISTRATION', value: 'NATIONAL ARCHIVES AND RECORDS ADMINISTRATION' },
        { label: 'HEALTH AND HUMAN SERVICES, DEPARTMENT OF', value: 'HEALTH AND HUMAN SERVICES, DEPARTMENT OF' },
        { label: 'CONSUMER FINANCIAL PROTECTION BUREAU', value: 'CONSUMER FINANCIAL PROTECTION BUREAU' },
        { label: 'UNITED STATES AGENCY FOR GLOBAL MEDIA, BBG', value: 'UNITED STATES AGENCY FOR GLOBAL MEDIA, BBG' },
        { label: 'STATE, DEPARTMENT OF', value: 'STATE, DEPARTMENT OF' },
        { label: 'TRANSPORTATION, DEPARTMENT OF', value: 'TRANSPORTATION, DEPARTMENT OF' },
        { label: 'EXECUTIVE OFFICE OF THE PRESIDENT', value: 'EXECUTIVE OFFICE OF THE PRESIDENT' },
        { label: 'INTERIOR, DEPARTMENT OF THE', value: 'INTERIOR, DEPARTMENT OF THE' },
        { label: 'LABOR, DEPARTMENT OF', value: 'LABOR, DEPARTMENT OF' },
        { label: 'GOVERNMENT ACCOUNTABILITY OFFICE', value: 'GOVERNMENT ACCOUNTABILITY OFFICE' },
        { label: 'FEDERAL TRADE COMMISSION', value: 'FEDERAL TRADE COMMISSION' },
        { label: 'MILLENNIUM CHALLENGE CORPORATION', value: 'MILLENNIUM CHALLENGE CORPORATION' },
        { label: 'EDUCATION, DEPARTMENT OF', value: 'EDUCATION, DEPARTMENT OF' },
        { label: 'PEACE CORPS', value: 'PEACE CORPS' },
        { label: 'SECURITIES AND EXCHANGE COMMISSION', value: 'AttaSECURITIES AND EXCHANGE COMMISSIONchment' },
        { label: 'UNITED STATES INTERNATIONAL DEVELOPMENT FINANCE CORPORATION', value: 'UNITED STATES INTERNATIONAL DEVELOPMENT FINANCE CORPORATION' },
        { label: 'SMALL BUSINESS ADMINISTRATION', value: 'SMALL BUSINESS ADMINISTRATION' },
        { label: 'PENSION BENEFIT GUARANTY CORPORATION', value: 'PENSION BENEFIT GUARANTY CORPORATION' },
        { label: 'NATIONAL SCIENCE FOUNDATION', value: 'NATIONAL SCIENCE FOUNDATION' },
        { label: 'HOUSING AND URBAN DEVELOPMENT, DEPARTMENT OF', value: 'HOUSING AND URBAN DEVELOPMENT, DEPARTMENT OF' },
        { label: 'FEDERAL COMMUNICATIONS COMMISSION', value: 'FEDERAL COMMUNICATIONS COMMISSION' },
        { label: 'NUCLEAR REGULATORY COMMISSION', value: 'NUCLEAR REGULATORY COMMISSION' },
        { label: 'EXPORT-IMPORT BANK OF THE U.S.', value: 'EXPORT-IMPORT BANK OF THE U.S.' },
        { label: 'OFFICE OF PERSONNEL MANAGEMENT', value: 'OFFICE OF PERSONNEL MANAGEMENT' },
        { label: 'COMMODITY FUTURES TRADING COMMISSION', value: 'COMMODITY FUTURES TRADING COMMISSION' },
        { label: 'EQUAL EMPLOYMENT OPPORTUNITY COMMISSION', value: 'EQUAL EMPLOYMENT OPPORTUNITY COMMISSION' },
        { label: 'NATIONAL TRANSPORTATION SAFETY BOARD', value: 'NATIONAL TRANSPORTATION SAFETY BOARD' },
        { label: 'CONSUMER PRODUCT SAFETY COMMISSION', value: 'CONSUMER PRODUCT SAFETY COMMISSION' },
        { label: 'NATIONAL GALLERY OF ART', value: 'NATIONAL GALLERY OF ART' },
        { label: 'INTERNATIONAL BOUNDARY AND WATER COMMISSION: U.S.-MEXICO', value: 'INTERNATIONAL BOUNDARY AND WATER COMMISSION: U.S.-MEXICO' },
        { label: 'CORPORATION FOR NATIONAL AND COMMUNITY SERVICE', value: 'CORPORATION FOR NATIONAL AND COMMUNITY SERVICE' },
        { label: 'FEDERAL LABOR RELATIONS AUTHORITY', value: 'FEDERAL LABOR RELATIONS AUTHORITY' },
        { label: 'COURT SERVICES AND OFFENDER SUPERVISION AGENCY', value: 'COURT SERVICES AND OFFENDER SUPERVISION AGENCY' },
        { label: 'RAILROAD RETIREMENT BOARD', value: 'RAILROAD RETIREMENT BOARD' },
        { label: 'J. F. KENNEDY CENTER FOR THE PERFORMING ARTS', value: 'J. F. KENNEDY CENTER FOR THE PERFORMING ARTS' },
        { label: 'FEDERAL ELECTION COMMISSION', value: 'FEDERAL ELECTION COMMISSION' },
        { label: 'NATIONAL LABOR RELATIONS BOARD', value: 'NATIONAL LABOR RELATIONS BOARD' },
        { label: 'DEFENSE NUCLEAR FACILITIES SAFETY BOARD', value: 'DEFENSE NUCLEAR FACILITIES SAFETY BOARD' },
        { label: 'INTERNATIONAL TRADE COMMISSION', value: 'INTERNATIONAL TRADE COMMISSION' },
        { label: 'MERIT SYSTEMS PROTECTION BOARD', value: 'MERIT SYSTEMS PROTECTION BOARD' },
        { label: 'FEDERAL HOUSING FINANCE AGENCY', value: 'FEDERAL HOUSING FINANCE AGENCY' },
        { label: 'FEDERAL MEDIATION AND CONCILIATION SERVICE', value: 'FEDERAL MEDIATION AND CONCILIATION SERVICE' },
        { label: 'UNITED STATES TRADE AND DEVELOPMENT AGENCY', value: 'UNITED STATES TRADE AND DEVELOPMENT AGENCY' },
        { label: 'FEDERAL MARITIME COMMISSION', value: 'FEDERAL MARITIME COMMISSION' },
        { label: 'DISTRICT OF COLUMBIA COURTS', value: 'DISTRICT OF COLUMBIA COURTS' },
        { label: 'COMMITTEE FOR PURCHASE FROM PEOPLE WHO ARE BLIND OR SEVERELY DISABLED', value: 'COMMITTEE FOR PURCHASE FROM PEOPLE WHO ARE BLIND OR SEVERELY DISABLED' },
        { label: 'SELECTIVE SERVICE SYSTEM', value: 'SELECTIVE SERVICE SYSTEM' }
    ];

    const AiData = [
        { label: 'Select All', value: 'select-all' },
        { label: 'Attachment', value: 'Attachment' },
        { label: 'Technical Approach', value: 'Technical Approach' },
        { label: 'Letter of Interest', value: 'Letter of Interest' },
        { label: 'Project Organization Chart', value: 'Project Organization Chart' },
        { label: 'Desired Features', value: 'Desired Features' },
        { label: 'Resumes', value: 'Resumes' },
        { label: 'Firm Financial Information ', value: 'Firm Financial Information' },
        { label: 'Cost Summary', value: 'Cost Summary' },
        { label: 'Executive Summary', value: 'Executive Summary' },
        { label: 'Needs Assessment', value: 'Needs Assessment' },
        { label: 'Goals and Objectives', value: 'Goals and Objectives' },
        { label: 'Benefits', value: 'Benefits' },
        { label: 'Project Deliverables', value: 'Project Deliverables' },
        { label: 'Training Plan', value: 'Training Plan' },
        { label: 'Schedule and Milestones', value: 'Schedule and Milestones' },
        { label: 'Expected Result', value: 'Expected Result' },
        { label: 'Project Managment', value: 'Project Managment' },
        { label: 'Communication Plan', value: 'Communication Plan' },
        { label: 'Deployment Recommendations', value: 'Deployment Recommendations' },
        { label: 'Company History', value: 'Company History' },
        { label: 'Similar Projects', value: 'Similar Projects' },
        { label: 'Project Assumptions', value: 'Project Assumptions' },
        { label: 'Team Capabilities', value: 'Team Capabilities' },
        { label: 'Testimonials', value: 'Testimonials' },
        { label: 'Maintenance and Support', value: 'Maintenance and Support' }

    ];

    const [fullProposal, setFullProposal] = useState()
    const [resourceLinks, setResourceLink] = useState([]);
    const [opportunity, setOpportunity] = useState(null);
    const [documentData, setDocumentData] = useState(null);
    const [assetData, setAssetData] = useState([])
    const [isOpenResourceLinkModal, setOpenResourceLinkModal] = useState(false);
    const [departMentSector, setDepartmentSector] = useState("");
    const [manualSections, setManualSections] = useState([]);
    const [aiGeneratedSections, setAiGeneratedSections] = useState([]);
    const [assetSectionTemplateData, setAssetSectionTemplateData] = useState([])
    const [assetSectionDataValidation, setAssetSectionDataValidation] = useState([])
    const [imageFileNames, setImageFileNames] = useState({});
    console.log(manualSections, aiGeneratedSections, "dataofsections")
    const customStyles1 = {
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#1976d2',
            color: 'white',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: 'white',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'white',
            ':hover': {
                backgroundColor: '#145a86',
                color: 'white',
            },
        }),
        control: (provided) => ({
            ...provided,
            height: '50px', // Fixed height for the Select control
            overflowY: 'auto', // Make it scrollable
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
        }),
    };


    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);
    const [fullProposalCurrentPage, setFullProposalCurrentPage] = useState(1);
    const [myTemplatesCurrentPage, setMyTemplatesCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(windowWidth > 991 ? 4 : windowWidth > 767 ? 6 : 4);

    const [originalLabels, setOriginalLabels] = useState([]);
    const [showOriginalLabels, setShowOriginalLabels] = useState(false);

       
    useEffect(() => {
        // Define the handleResize function
        const handleResize = () => {
            const newWindowWidth = window.innerWidth;
            setWindowWidth(newWindowWidth < 768);  // This sets window width status for mobile or desktop
            setDeviceWidth(newWindowWidth);        // Update the device width state

            // Check the value of fullProposalCurrentPage to determine items per page
            if (fullProposalCurrentPage === 1) {
                setItemsPerPage(3);   // Set items per page to 3 if fullProposalCurrentPage is 1
            } else {
                setItemsPerPage(4);   // Default to 4 items per page for other cases
            }
        };

        // Add the resize event listener
        window.addEventListener('resize', handleResize);

        // Call handleResize initially to set the itemsPerPage on the first render
        handleResize();

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, [fullProposalCurrentPage]);  // Re-run the effect when fullProposalCurrentPage changes

    // Additional conditional check outside useEffect, if needed


    useEffect(() => {
        console.log("checkk", rfmManagementData)
        setTypeOfAsset(templateDatareduxvalues.templatData.showtemplate)
        setAssetSectionData(rfmManagementData.ProposalData.assetid)
        setAssetId(rfmManagementData.ProposalData.assetid)
        const firstValue = rfmManagementData?.value?.agancy?.split('.')[0];
        if (firstValue) {
            setDepartmentSector(firstValue)
            console.log("firstValue", firstValue)
        }


        if (typeOfAsset == "RFP") {
            setAssetType("rfp")
            //  getAssetSectionData("rfp")

        }
        if (typeOfAsset == "PROPOSAL") {
            setAssetType("proposal")
            //  getAssetSectionData("rfp")

        }


        // template upload button 
            // <Button
            //     className="CTAButtons"
            //     data-tip
            //     data-for="uploadsectiondoc"
            //     component="label"
            //     htmlFor="OppoUpload"
            // >
            //     Upload

            //     <span style={{ marginLeft: "8px" }}>
            //         <FileUploadIcon />
            //     </span>
            //     <VisuallyHiddenInput
            //         id="OppoUpload"
            //         type="file"
            //         onChange={handleFileChange} 
            //     />
            // </Button>
                                   






        setTemplateReduxData(templateDatareduxvalues.templatData)
        console.log("props passed to this component", templateDatareduxvalues, "66666666666666666666", rfmManagementData.ProposalData)
        console.log("assetId    ", rfmManagementData.ProposalData.assetid)


    }, [templateData])


    useEffect(() => {
        if (popupForm === false) {
            setFormData('')
            setDataTypeMap("")
        }
        console.log("formData", formData)
        console.log("dataTypeMap", dataTypeMap)
    }, [formData, popupForm])

    const [fullProposalvrushabh, setFullProposalvrushabh] = useState()
    const fetchTemplate = async () => {
        let data = {
            path: "titlePages/Building1/"
        }
        try {
            const response = await getS3Data(data)
            if (response.status == 200) {
                console.log("response of full proposal", response)
                setFullProposalvrushabh(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {

        fetchTemplate1();
    }, [])


    const handleDataTypeChange = (event, label) => {
        const { value } = event.target;
        setDataTypeMap(prevState => ({
            ...prevState,
            [label]: value // Associate the selected value with the label
        }));
    };

    // const handleDatePicker = (e, label) => {
    //     // console.log("Date1",date.toLocaleDateString())
    //     const Newlabel = [label.toLowerCase().replace(/\s/g, "-")]
    //     const formattedDate = e.target.value
    //     console.log("Date2", formattedDate, label)
    //     setFormData(prevFormData => ({
    //         ...prevFormData,
    //         [Newlabel]: formattedDate
    //     }));
    //     // setFormData({ ...formData, [label]: formattedDate })

    // };
    const handleDatePicker = (e, label, isFrom = true) => {
        const dateKey = isFrom ? "from-date" : "to-date";
        const formattedDate = e.target.value;

        console.log("Date2", formattedDate, label);
        if(label==="Month Day, Year"){
        setFormData(prevFormData => ({
            ...prevFormData,
            [dateKey]: formattedDate,
        }));
    }else{
        setFormData(prevFormData => ({
            ...prevFormData,
            [label.toLowerCase().replace(/\s/g, "-")]: formattedDate,
        }));

    }
    };


    //old function
    // const handleInputChange = (event) => {

    //     setFormData({ ...formData, [event.target.name]: event.target.value });
    //     console.log("Oneeee  ", ...formData, "Two    ", event.target.name, "Three   ", event.target.value)
    //     console.log("form daaaa  ", formData)

    // };
    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // Batch state updates using a callback function
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));

        // Remove console.log statements that log the previous state immediately
        // console.log("Oneeee  ", ...formData, "Two    ", name, "Three   ", value);
        // console.log("form daaaa  ", formData);
    };

    //old function
    // function HandleRefreshPlaceholder(){
    //     const updatedContent = placeholder?.replace(
    //                 /&lt;&lt;([^&]+?)&gt;&gt;|\[([^\]]+)]/g,
    //                 (match, angleBracketLabel, squareBracketLabel) => {
    //                     let labelText = angleBracketLabel || squareBracketLabel;
    //                     console.log("label123  ", labelText)
    //                     return formData[labelText.toLowerCase().replace(/\s/g, "-")] || `[${labelText}]` || `[${labelText}]`;

    //                 }
    //             );

    //             console.log("123 ", updatedContent);
    //             setPlaceholder(updatedContent);


    // }


    //second function working correct
    // function HandleRefreshPlaceholder() {
    //     setPlaceholder(prevPlaceholder => {
    //         let originalPlaceholder = prevPlaceholder; // Store a copy of the original placeholder
    // console.log("123  ", prevPlaceholder)
    //         const updatedContent = prevPlaceholder?.replace(
    //             /&lt;&lt;([^&]+?)&gt;&gt;|\[([^\]]+)]/g,
    //             (match, angleBracketLabel, squareBracketLabel) => {
    //                 let labelText = angleBracketLabel || squareBracketLabel;
    //                 console.log("321  ",labelText)
    //                 const placeholderKey = labelText.toLowerCase().replace(/\s/g, "-");
    //                 console.log("432  ",placeholderKey)
    //                 return formData[placeholderKey] || `[${labelText}]`;
    //             }
    //         );

    //         console.log("Updated content:123 ", updatedContent);

    //         // Return the updatedContent with placeholders, or revert to the originalPlaceholder
    //         return updatedContent || originalPlaceholder;
    //     });
    // }
    const [replacedValues, setReplacedValues] = useState({});
    const [isPreviewDone, setIsPreviewDone] = useState(false);


    let monthDayYearPreviewCount = 0;
    const HandleRefreshPlaceholder = () => {
        // setPlaceholder(prevPlaceholder => {
        //     const updatedContent = prevPlaceholder.replace(
        //         /&lt;&lt;([^&]+?)&gt;&gt;|\[([^\]]+)]/g,
        //         (match, angleBracketLabel, squareBracketLabel) => {
        //             let labelText = angleBracketLabel || squareBracketLabel;
        //             const placeholderKey = labelText.toLowerCase().replace(/\s/g, "-");

        //             console.log("oneee  ", labelText)
        //             console.log("twooo  ", placeholderKey)


        //             const replacedValue = replacedValues[placeholderKey];
        //             console.log("three  ", replacedValue)


        //             if (replacedValue !== undefined) {
        //                 return replacedValue; // Use replaced value if available
        //             } else {
        //                 // Check if formData contains a value for the placeholderKey
        //                 const formDataValue = formData[placeholderKey];
        //                 if (formDataValue !== undefined) {
        //                     // Return the formDataValue wrapped in square brackets
        //                     console.log("fourrr  ", formDataValue)
        //                     const newValue = `[${formDataValue}]`;
        //                     setReplacedValues(prevValues => ({
        //                         ...prevValues,
        //                         [placeholderKey]: newValue
        //                     }));
        //                     console.log("fiveee   ", replacedValues)

        //                     return newValue;
        //                 } else {
        //                     // If no formData value is found, return the original labelText wrapped in square brackets
        //                     const newValue = `[${labelText}]`;
        //                     setReplacedValues(prevValues => ({
        //                         ...prevValues,
        //                         [placeholderKey]: newValue
        //                     }));
        //                     console.log("fiveee   ", replacedValues)

        //                     return newValue;
        //                 }
        //             }
        //         }
        //     );

        //     return updatedContent;
        // });

        // alert("inside preview")
        monthDayYearPreviewCount = 0;

        const updatedContent = placeholder?.replace(
            /&lt;&lt;([^&]+?)&gt;&gt;|\[([^\]]+)]/g,
            (match, angleBracketLabel, squareBracketLabel) => {
                // Extract the label text from either the << >> or [] brackets
                let labelText = angleBracketLabel || squareBracketLabel;

                // Log the labelText for debugging
                console.log("Label Text: ", labelText);

                let dataValue;

        // Check if labelText is "Month Day, Year" and handle occurrences
        if (labelText.trim().toLowerCase() === "month day, year") {
            monthDayYearPreviewCount++;

            // Use "from-date" for the first occurrence, "to-date" for the second
            if (monthDayYearPreviewCount === 1) {
                dataValue = formData["from-date"] || '';
            } else if (monthDayYearPreviewCount === 2) {
                dataValue = formData["to-date"] || '';
            }
        } else {
            // General case for other labels: map to formData using lowercase, hyphenated labelText
            dataValue = formData[labelText.toLowerCase().replace(/\s/g, "-")] || '';
        }

        // Log dataValue for debugging to ensure it's set for all label

        // Preserve original brackets in the replacement
        if (dataValue) {
            return `${match[0]}${dataValue}${match[match.length - 1]}`;
        }
        
        // If no matching data, return the original match
        return match;
    }
);


        console.log("rightsidePreview", updatedContent);
        setPlaceholder(updatedContent)
        setIsPreviewDone(true)
        setShowOriginalLabels(false)
    };




    // useEffect(()=> {
    //     console.log("DDDD  ", formData)

    //     const updatedContent = placeholder?.replace(
    //         /&lt;&lt;([^&]+?)&gt;&gt;|\[([^\]]+)]/g,
    //         (match, angleBracketLabel, squareBracketLabel) => {
    //             let labelText = angleBracketLabel || squareBracketLabel;

    //             return formData[labelText.toLowerCase().replace(/\s/g, "-")] || '';

    //         }
    //     );

    //     console.log("JODIT sCAN  ", updatedContent);
    //     setPlaceholder(updatedContent);


    // }, [formData])

    let monthDayYearCount = 0; 

    const handleSubmitClick = () => {

        console.log(placeholder, "cccckckkkc")
        const updatedContent = placeholder?.replace(
            /&lt;&lt;([^&]+?)&gt;&gt;|\[([^\]]+)]/g,
            (match, angleBracketLabel, squareBracketLabel) => {
                let labelText = angleBracketLabel || squareBracketLabel;
                console.log("texxt ", labelText)
                
                if (labelText.trim().toLowerCase() === "month day, year") {
                    monthDayYearCount++; // Increment the count for "Month Day, Year"
        
                    // If it's the first occurrence, use "from-date", otherwise use "to-date"
                    if (monthDayYearCount === 1) {
                        return formData["from-date"] || '';
                    } else if (monthDayYearCount === 2) {
                        return formData["to-date"] || '';
                    }
                }

                return formData[labelText.toLowerCase().replace(/\s/g, "-")] || '';
                // return labelText

            }
        );

        console.log("JODIT sCANnnnnn  ", updatedContent);
        if (isPreviewDone) {
            console.log("submit data after preview:", placeholder)
            setContent(placeholder)
        } else {
            setContent(updatedContent);
        }

        setShowForm(false);
        setProceedClicked(true);
        setFormData({})
        setImageFileNames({})
        setIsPreviewDone(false)
        setShowOriginalLabels(false)

    };
    // const handleSubmitClick = () => {
    //     console.log(placeholder, "cccckckkkc");

    //     // First, replace the "From" and "To" dates
    //     let updatedContent = placeholder?.replace(
    //         /From:\s?\[Month Day, Year\]\s?To:\s?\[Month Day, Year\]/g,
    //         () => {
    //             const fromDate = formData["from-date"] || "[Month Day, Year]";
    //             const toDate = formData["to-date"] || "[Month Day, Year]";
    //             return `From: ${fromDate} To: ${toDate}`;
    //         }
    //     );

    //     // Then, replace the [Provide a brief description] placeholder
    //     updatedContent = updatedContent.replace(
    //         /\[Provide a brief description\]/g,
    //         formData["provide-a-brief-description"] || "[Provide a brief description]"
    //     );

    //     console.log("JODIT updated content: ", updatedContent);
    //     setContent(updatedContent);

    //     setShowForm(false);
    //     setProceedClicked(true);
    //     setFormData({});
    // };


    ////////////////////////
    const [isEditable, setIsEditable] = useState(false);

    // Handle change for the input field
    const handleChange = (e) => {
        const fileName = e.target.value
        setCheckedTempName(fileName);
    };

    // Handle edit icon click
    const handleEdit = () => {
        setIsEditable(true);
    };

    // Handle save button click
    const handleSave = () => {
        if (!checkedTempName.trim()) {
            toast.error('Template name cannot be blank');
            return;
        }
        setIsEditable(false);
        // alert('Template name saved:', checkedTempName); 
    };

    const handleAddopenModal = () => {
        setTitle('')
        setaddDescription('')
        setOpenAddModal(true)
    }


    // const [orgName, setOrgName] = useState('')
    // const [userid, setUserId] = useState('')
    const handleOpen = (name, id) => {
        setTemplateToDelete(id);
        setOpen(true);
    };

    const handleClose1 = () => {
        setOpen(false);
    };

    const handleCancelClick = () => {
        // Close the delete confirmation modal without deleting
        handleClose1();
    };

    const config = {

        backgroundColor: "yellow",
        branding: false,
        statusbar: false,
        readonly: false,
        height: "85vh",
        uploader: {
            insertImageAsBase64URI: true, // Enable Base64 encoding
        },
        controls: {
            paragraph: {
                list: Jodit.atom({
                    p: 'Paragraph',
                    h1: 'Heading 1',
                    h2: 'Heading 2',
                    h3: 'Heading 3',
                    h4: 'Heading 4',
                    blockquote: 'Quote',
                })
            }
        },
    };



    const config2 = {

        toolbar: false,
        branding: false,
        statusbar: false,
        readonly: true,
        height: "100%",
        uploader: {
            insertImageAsBase64URI: true, // Enable Base64 encoding
        }
    };



    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });


    const handlePreviewClick = (name, path) => {
        if (name === "Blank Template") {
            setIsPreviewOpen(true)
            setContent("")
            return
        }

        fetchDataFile(name, path)
    };

    useEffect(() => {
        const Username = sessionStorage.getItem("currentUserEmail")
        getCuurentUser(Username)
        setCuurentUser(Username)
        fetchTemplate()
    }, [])

    const getCuurentUser = async (Username) => {
        try {
            const response = await getUserInfo(Username)
            console.log("userinfo", response.data)
            setUserInfo(response.data)
            // setOrgName(response?.data?.organizationName)
            // setUserId(response?.data?._id)
        } catch (error) {
            console.log(error)
        }
    }

    console.log("userinfo1", userInfo)



    // Its will fetch the data in editor
    let userid = userInfo?._id;
    let orgName = userInfo?.organizationName;


    const handleBlankPreviewClick = () => {
        setIsPreviewOpen(true)
        setPreviewContent("")
    }


    const handleClosePopup = () => {
        setPopupOpen(false);
        setFileURL()
    };

    const handleBack = () => {
        // if (showtemplate) {
        //     hideAddTemplate()
        // }
        // if (showtemplate1) {

        //     hideAddTemplateForProposal()
        // }

        history.goBack();


    }
    //     useEffect(() => {
    //         if (typeOfAsset == "PROPOSAL") {
    //             setAssetType("proposal")
    //             getAssetSectionData("proposal")
    //             alert("proposal")

    //         }

    //         if (typeOfAsset == "RFP") {
    //             setAssetType("rfp")
    //             getAssetSectionData("rfp")
    //             alert("rfp")
    //         }

    // alert(typeOfAsset)

    //     }, [])

    const [showEditIcon, setShowEditIcon] = useState(false)

    const handleProceed = (name, title, id, path) => {
        if (name !== "Blank Template") {
            // alert( `inside not blank${name}, ${id},id,${title},title, ${path}`)
            setIdForFile(id)
            setCheckedTempName(title)
            setCheckedTitle(title)
            fetchDataInEditor(name, id, path)
            if (name == "html_content") {
                setShowEditIcon(true)
            }
        } else {
            // alert( `inside  blank ${id},id,${title},title, ${path}`)
            setCheckedTempName('Blank-Template')
            setShowEditIcon(true)
            //    MergeTemplate();\

        }
        setProceedClicked(true);

    };

    console.log(asssetsectionData, "asssetsectionData")



    const MergeTemplate = async () => {


        console.log("aasseett  ", AssetId, "F   ", asssetsectionData, "    e  ", assetType)

        let data = {
            assetId: asssetsectionData,
            // assetId: AssetId,
            assetType: assetType
        }


        let response = await TemplateMerge(data)



        console.log("response from mmmerge", response)

        handleBlankTemplate(response.all)


    }



    // const getAssetSectionData = async (assetType1) => {


    //     console.log("ppp  ",  "   asset   ", assetType1)


    //     try {
    //         const response = await getAssetsection(opportunityId, assetType1)
    //         if (response) {


    //             console.log("respon  ", response)
    //             console.log("response of asssetsection2 ", response.data[0].assetId)
    //             setAssetSectionData(response.data[0].assetId)
    //         }
    //         else {
    //             console.log("error in response")
    //         }
    //     } catch (e) {
    //         console.log(e, "errrororrooror")
    //     }
    // }




    const handleBlankTemplate = (dataArray1) => {



        // Define the background color for each "page"
        const pageBackgroundColor = 'white'; // Adjust the color as needed

        // Define the height of the empty space between items
        const emptySpaceHeight = '80px'; // Adjust the height as needed
        console.log(dataArray1, "dataArray")
        // Extract data field from dataArray and wrap each element in a div with background color
        const extracted = dataArray1.map(item => `<div style="background-color: ${pageBackgroundColor}; width: 100%; padding: 50px;"><div style="background-color:${pageBackgroundColor};">${item}</div></div><div style="height: ${emptySpaceHeight};"></div>`).join('');
        setContent(extracted);



        console.log('Content in mergeeeee', content)
        // setProceedClicked(true)
    };



    const fetchData = async () => {
        try {
            setLoading(true);

            const email = sessionStorage.getItem("currentUserEmail");

            // Include email in the request to the backend
            const response = await getTemplates({ email });
            if (response.data !== null) {
                const data1 = response.data;
                console.log("DATA FROM BUCKET  ", response.data1);
                setTemplateData(data1); // Log or process the data received from the API
            }

        } catch (error) {
            console.error('Error fetching templates:', error.message);
        } finally {
            // setCheckedTempName(null);
            setCheckedBox(null);
            setLoading(false);
            // setContent("")
        }
    };







    const handleProceedBack = () => {
        setPopupForm(false)
        setProceedClicked(false)
        setContent("");
        setIdForFile(null)
        setCheckedTempName("");
        fetchData()
        setShowEditIcon(false)
        setIsPreviewDone(false)

    }

    useEffect(() => {

        fetchData();

    }, []);

    function showNoPreviewInIframe() {
        const iframe = document.getElementById('previewIframe');
        const noPreviewMessage = '<div style="font-family: Arial, sans-serif; background-color: #f4f4f4; padding: 20px; text-align: center;">' +
            '<h1>No Preview Available</h1>' +
            '<p>The document format is not supported or there was an error.</p>' +
            '</div>';

        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(noPreviewMessage);
        iframe.contentWindow.document.close();
    }

    const closePreview = () => {
        setIsPreviewOpen(false)
    }

    async function fetchDataFile(name, path) {


        const urlParts = path.split('/');

        // Get the last part of the URL
        let filenames = urlParts[urlParts.length - 1];
        setLoading(true)
        try {
            const filename = filenames
            let docFormat = name.slice(-4).replace(/\./g, '');
            let userid = userInfo?._id;
            let orgName = userInfo?.organizationName;

            // .slice(-4).replace(/\./g, '');
            const docxContent = await getFileContent(filename, orgName, userid);

            console.log("fetchDataFile", docxContent)
            console.log(docFormat, "docFormat")
            if (docxContent.rawResponse.status == 200) {

                if (docFormat == "doc") {
                    showToast('Warning.noPreview')
                }
                else if (docFormat == "docx") {
                    console.log("Editorresponse  ", docxContent.textResponse)
                    setPreviewContent(docxContent.textResponse)
                    setIsPreviewOpen(true)

                }
                else if (docFormat == "html") {
                    console.log("Editorresponse  ", docxContent.textResponse)
                    setPreviewContent(docxContent.textResponse)
                    setIsPreviewOpen(true)

                }
                else if (docFormat == "pdf") {
                    setFileURL(docxContent.blobURL)
                    setPopupOpen(true)
                    console.log("")
                }
            }
            else {

                showToast('Error.errorOccured')
            }
        } catch (error) {
            console.error('Error fetching and processing file content:', error);
            showToast('Error.errorOccured')
        }
        setLoading(false)
    }

    async function fetchDataInEditor(name, id, path) {
        console.log("ScanFile2    ", name, " ", path)


        const urlParts = path.split('/');

        // Get the last part of the URL
        let filenames = urlParts[urlParts.length - 1];


        setLoading(true)
        try {
            const filename = filenames

            console.log(userid, "userid")
            console.log(orgName, filename, "orgname")
            let docFormat = filename.slice(-4).replace(/\./g, '');
            const docxContent = await getFileContent(filename, orgName, userid);

            console.log("fetchDataFile", docxContent, filename, docFormat)
            if (docFormat == "doc") {
                showToast('Warning.noPreview')
            }
            else if (docFormat == "docx") {

                console.log("DOCX CONTENT ", docxContent.textResponse)
                setContent(docxContent.textResponse)

            }
            else if (docFormat == "html") {

                console.log("DOCX CONTENT ", docxContent.textResponse)
                setContent(docxContent.textResponse)
            }

            else if (docFormat == "pdf") {
                setFileURL(docxContent.blobURL)
                //  setPopupOpen(true)
            }
        } catch (error) {
            console.error('Error fetching and processing file content:', error);
            // showNoPreviewInIframe();
        }
        setLoading(false)
    }


    async function fetchDataForFormScan(name, id, path) {
        console.log("ScanFile    ", name, " ", path)

        const urlParts = path.split('/');

        // Get the last part of the URL
        let filenames = urlParts[urlParts.length - 1];


        setLoading(true)
        try {
            const filename = filenames

            console.log(userid, "userid")
            console.log(orgName, filename, "orgname")
            let docFormat = filename.slice(-4).replace(/\./g, '');
            const docxContent = await getFileContent(filename, orgName, userid);


            console.log("fetchDataFile", docxContent, filename, docFormat)
            if (docFormat == "doc") {
                showToast('Warning.noPreview')
            }
            else if (docFormat == "docx") {

                console.log("DOCX CONTENT ", docxContent.textResponse)
                setContentForScan(docxContent.textResponse)
                setPlaceholder(docxContent.textResponse)

            }
            else if (docFormat == "html") {

                console.log("DOCX CONTENT ", docxContent.textResponse)
                setContentForScan(docxContent.textResponse)
                setPlaceholder(docxContent.textResponse)

            }

            else if (docFormat == "pdf") {
                setFileURL(docxContent.blobURL)
                //  setPopupOpen(true)
            }
        } catch (error) {
            console.error('Error fetching and processing file content:', error);
            // showNoPreviewInIframe();
        }

        setLoading(false)
        console.log("SCANNN  ", contentForScan)

        setPopupForm(true)
    }


    const handleDeleteConfirmation = async (name, fileName, id) => {

        try {
            const email = sessionStorage.getItem("currentUserEmail")
            let title = name;
            let data = { email, title, fileName, id };
            console.log(data, "checkdata")
            const response = await axios.post(`https://api.contractpro360.com/api/bucket/deleteTemplates`, { data });

            if (response.status === 200) {
                // Trigger toast message only if the API request is successful
                console.log(response, "responsedelete");
                fetchData()
                showToast('Success.delete')
            }

        } catch (error) {
            console.log(error)
        }
        handleClose1()
    }

    const HandleSave = () => {
        console.log("CONTENT STATE  ", content)

        //     try {
        //         let response = await UploadTemplate(file)
        //         console.log("response from Upload ", response)
        //     }
        //     catch (error) {
        //         console.log("Error from upload ", error)
        //     }
    }


    // new function upload
    const FileUploadInList = async (e) => {
        const allowedFormats = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        const email = sessionStorage.getItem("currentUserEmail");
        console.log("File selected", e.target.files);
        const file = e.target.files[0];
        console.log("Upload  ", file)
        const formData = new FormData();

        // Append the email as a field in formData
        formData.append('email', email);
        formData.append('filename', file);



        if (!file) {
            showToast('Warning.noFile')
            return;
        }

        // Check if the file format is allowed
        if (!allowedFormats.includes(file.type)) {
            showToast('Warning.docUpload')
            return;
        }



        try {
            const response = await axios.post(`https://api.contractpro360.com/api/bucket/uploadfromlocal`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log("response", response)
            if (response) {
                console.log("upload response", response)
                showToast('Success.fileUpload')

                fetchData();
            }

        } catch (error) {
            console.error('Error uploading file:', error.message);
            // toast.warn("Error while uploading the file.");

            if (error.response && error.response.status === 400) {
                const response = error.response;
                console.log("Response on 400:", response);
                setFileData(file);
                setShowDuplicateModal(true);
                const Filteredname = response.data.filename.split('.').slice(0, -1).join('.')
                setExistingFileName(Filteredname);
                setDuplicateFileName('');
                setCopyButtonValidation(false)
                console.log("response.filename", response.data.filename, "---", Filteredname);
            }
        }
        if (proceedClicked == true) {
            setProceedClicked(false);
        };
    }



    const truncateFileName = (fileName, maxLength) => {
        console.log(fileName, maxLength,"XCXCX")
        if (fileName?.length <= maxLength) {
            return fileName;
        } else {
            return fileName?.substring(0, maxLength) + '...';
        }
    };


    const getType = (name) => {
        const extension = name.split('.').pop().toLowerCase();

        switch (extension) {
            case 'docx':
                return 'DOCX';
            case 'pdf':
                return 'PDF';
            case 'txt':
                return 'TXT';
            case 'doc':
                return 'DOC';
            // Add more cases for other formats as needed
            default:
                return 'Unknown Format';
        }
    };



    const handleTemplateupdate = async (filename, idForFile, path) => {

        const email = sessionStorage.getItem("currentUserEmail");
        let userid = userInfo?._id;
        let orgName = userInfo?.organizationName;
        console.log(content, email, userid, orgName, filename, "filename  ", path)
        try {
            const response = await axios.post(`https://api.contractpro360.com/api/bucket/updatedFile`, {
                email: email,
                orgName: orgName,
                userId: userid,
                filename: filename,
                updatedContent: content,
                TemplateId: idForFile,
                path: path
            });

            if (response.status === 200) {
                console.log("Upload response:", response.data);
                showToast('Success.fileUpdate')
                fetchData(); // Assuming fetchData is a function to fetch updated data
            } else {
                console.log("Error in upload");
                // toast.error("Error uploading file");
            }
        } catch (error) {
            console.error('Error uploading file:', error.message);
            showToast('Error.errorOccured')
        }
    }


    // const handleTemplateupdate = async (filename, id) => {
    //     const email = sessionStorage.getItem("currentUserEmail");
    //     // Convert HTML content to Blob
    //     const blob = new Blob([content], { type: 'text/html' });
    //     console.log("blob", blob)
    //     // Create FormData object
    //     const formData = new FormData();
    //     formData.append('filename', blob); // Append content as file
    //     formData.append('title', filename); // Append content as file
    //     formData.append('email', email);
    //     formData.append('id', id); // Append content as file
    //     formData.append('mainTitle', checkedTitle); // Append content as file
    //     let userid = userInfo?._id;
    //     let orgName = userInfo?.organizationName;

    //     formData.append('userid', userid)
    //     formData.append('orgName', orgName)
    //     // formData.append("htmlContent", htmlContent)
    //     console.log("htmlContent", filename)

    //     for (const pair of formData.entries()) {
    //         console.log("FormDataa  ", pair[0] + ', ' + pair[1]);
    //     }
    //     console.log(formData, "formdatata")

    //     try {
    //         const response = await axios.post(`https://api.contractpro360.com/api/bucket/uploadTemplate`, formData, {
    //         // const response = await axios.post(`https://api.contractpro360.com/api/bucket/uploadTemplate`, formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });

    //         if (response.status === 200) {
    //             console.log("upload responsesss   ", response)
    //             toast.success("upload successfully")
    //             fetchData();
    //         } else {
    //             console.log("error in upload")
    //         }
    //         // Handle success
    //         console.log('File uploaded successfully');
    //     } catch (error) {
    //         console.error('Error uploading file:', error.message);
    //     }
    //     console.log("htmlContent2", filename)

    // };

    // useEffect(()=>{
    //     getHtmlcontent() 
    // }, [content])



    const getHtmlcontent = (newContent) => {
        setHtmlContent(newContent)


        // if (editor.current) {
        //     const value = editor.current.value;


        //     console.log("Editor current value", value);



        //     const htmlContent = editor.current.value; // Access the HTML content
        //     console.log("htmlContent:", htmlContent);

        //     let wordCount = 0;
        //     let textContent = '';
        //     if (htmlContent === "") {

        //         setWordCount(0)
        //         return
        //     }

        //     if (htmlContent.trim() !== '') {
        //         // Replace line breaks with newline characters
        //         const processedHtmlContent = htmlContent.replace(/<br\s*\/?>/gi, '\n').replace(/<div\s*\/?>/gi, '\n');
        //         // Count words
        //         const tempElement = document.createElement('div');
        //         tempElement.innerHTML = processedHtmlContent;
        //         textContent = tempElement.innerText.trim(); // Use innerText instead of textContent
        //         wordCount = textContent.split(/\s+/).length;
        //     }
        //     setWordCount(wordCount)
        // }

    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");

        return `${day}-${month}-${year}`;
    };

    const handleProposalCreation = () => {

        // setShowProposalCreation(true)
        history.push("/ProposalCreationPage")
    }


    const handleFormClick = (name, title, id, path) => {
        fetchDataForFormScan(name, id, path)
    }

    const SignatureUpload = (e) => {
        console.log("File selected", e.target.files);
        const file = e.target.files[0];
        console.log("Upload 222  ", file)

        if (!file) {
            showToast('Warning.noFile')
            return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
            const imageUrl = event.target.result;
            const imageHtml = `<img src="${imageUrl}" alt="Signature" class="signature_Img" >`;
            setSignatureValue(imageHtml)
            console.log("imageHtml", imageHtml)

        };
        reader.readAsDataURL(file);

    }



    const logoUpload = (e, label) => {
        console.log("label to render ______", label);
        const file = e.target.files[0];
        const feildName = label.toLowerCase().replace(/\s/g, "-"); // Ensure feildName is a string
        console.log(file.name, "feildName", feildName);
        if (!file) {
            showToast('Warning.noFile')
            return;
        }
        // Update image file name in state
        setImageFileNames((prevState) => ({
            ...prevState,
            [feildName]: file.name
        }));

        const reader = new FileReader();
        reader.onload = (event) => {
            const imageUrl = event.target.result;
            const imageHtml = `<img src="${imageUrl}" alt="${label}"  >`;
            setFormData(prevState => ({
                ...prevState,
                [feildName]: imageHtml
            }));
        };
        reader.readAsDataURL(file);
    };

    useEffect(() => {
        const uniqueFields = [];
        const scannedFields1 = placeholder?.match(/&lt;&lt;([^&]+?)&gt;&gt;/g) || [];
        const scannedFields2 = placeholder?.match(/\[([^\]]+)]/g) || [];

        // Loop through scanned fields of type 1
        scannedFields1.forEach(match => {
            const labelText = match.slice(8, match.indexOf("&gt;&gt;"));
            if (!uniqueFields.includes(labelText)) {
                uniqueFields.push(labelText);
            }
        });

        // Loop through scanned fields of type 2
        scannedFields2.forEach(match => {
            const labelText = match.slice(1, -1); // Remove square brackets
            if (!uniqueFields.includes(labelText)) {
                uniqueFields.push(labelText);
            }
        });

        // Set fields to render initially
        if (originalLabels.length === 0) {
            setOriginalLabels(uniqueFields); // Store original labels only once
        }

        setFieldsToRender(uniqueFields);
    }, [placeholder]);

    useEffect(() => {
        const uniqueFields = [];
        const scannedFields1 = contentForScan?.match(/&lt;&lt;([^&]+?)&gt;&gt;/g) || [];
        const scannedFields2 = contentForScan?.match(/\[([^\]]+)]/g) || [];

        // Loop through scanned fields of type 1
        scannedFields1.forEach(match => {
            const labelText = match.slice(8, match.indexOf("&gt;&gt;"));
            if (!uniqueFields.includes(labelText)) {
                uniqueFields.push(labelText);
            }
        });

        // Loop through scanned fields of type 2
        scannedFields2.forEach(match => {
            const labelText = match.slice(1, -1); // Remove square brackets
            if (!uniqueFields.includes(labelText)) {
                uniqueFields.push(labelText);
            }
        });

        setFieldsToRender(uniqueFields);
    }, [contentForScan]);
    console.log(fieldsToRender,"__setFieldsToRender")

    const handleCopyTemplate = (e, value) => {
        setFileData('')
        const Name = e.split('.').slice(0, -1).join('.')
        setExistingFileName('')
        setDuplicateFileName(Name)
        setOldFileName(Name)
        setShowDuplicateModal(true)
        let filtervalue = value.split('/').slice(3).join('/')
        filtervalue = filtervalue.replace('%40', '@');
        setExistingCopyDocumentPath(filtervalue)
        console.log("filtervalue", filtervalue)
        setCopyButtonValidation(true)
    }

    const handlesduplicateFileName = (e) => {
        const ChangedName = e
        setDuplicateFileName(ChangedName)
        setExistingFileName('')
    }



    const handleExistingFileRename = async () => {
        console.log("duplicateFileName", duplicateFileName)

        const email = sessionStorage.getItem("currentUserEmail");
        // Append the email as a field in formData
        setLoading(true)

        if (copyButtonValidation === true) {
            try {
                console.log(oldFileName, "duplicateFileNameduplicateFileName")
                let fileLocation = existingCopyDocumentPath;
                console.log(fileLocation, "fileLocation")
                const parts = fileLocation.split('/');
                parts[parts.length - 1] = oldFileName;
                const newPath = parts.join('/');
                let data = {
                    email: email,
                    manualname: duplicateFileName,
                    pathForDuplicateCopy: newPath
                };
                console.log("DATTATATTATATTAAAA", data)
                const response = await copyTemplate(data)

                if (response.status === 200) {
                    setExistingFileName('')
                    setShowDuplicateModal(false)
                    setLoading(false)
                    fetchData();
                    showToast('Success.copyCreated')
                }
                else if (response.status === 400) {
                    toast.warn(response.message)
                    setLoading(false)
                }
                console.log("++++++++++++++++++++++++++", response)
            } catch (error) {
                console.log("error in copy button", error.response)
                setLoading(false)
            }

        }
        else if (copyButtonValidation === false) {

            try {
                const formData = new FormData();
                formData.append('email', email);
                formData.append('filename', fileData);
                formData.append('manualname', duplicateFileName ? duplicateFileName : existingFileName)
                console.log(formData, "abhay11")
                const response = await axios.post(`https://api.contractpro360.com/api/bucket/uploadfromlocal`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                console.log("++++++++++++++++++++++++++", response)
                if (response.status === 200) {
                    setExistingFileName('')
                    setShowDuplicateModal(false)
                    fetchData();
                    setLoading(false)
                    showToast('Success.upload')
                }
            } catch (error) {
                console.log("error.response", error.response, "[[[[[}", error.response.data.status)
                if (error.response.data.status == 400) {
                    showToast('Error.errorOccured')
                }
                setLoading(false)
            }
        }

    }



    const handleGalleryClick = () => {
        history.push("/TemplateGallery")
    }

    const handleDownload = () => {
        const editorContent = editor.current.value.replace(/<p>|<\/p>|<br\s*\/?>/g, '').trim();
        console.log(editorContent,"nononon",content)
        if(editorContent === ''){
            toast.error("Template is empty")
            return
        }

        setShowDownloadModal(true)
    }




    const downloadSinglePDF = () => {
        let fileName = checkedTempName.slice(0, -5);

        const pdfContent = `<div><h5>${fileName}</h5><p>${editor.current.value}</p></div>`;

        const element = document.createElement('div');
        element.innerHTML = pdfContent;

        html2pdf(element, {
            margin: 10,
            filename: `${fileName}.pdf`, // Set the filename to the document name
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        }).then((pdf) => {
            saveAs(pdf, `${fileName}.pdf`);
        });
        //onHide();
        setShowDownloadModal(false)
    };


    function updateImgWidth(match, p1, p2, p3) {
        // p2 is the current width value
        const currentWidth = parseInt(p2, 10);
        // Check if the current width is less than 600
        if (currentWidth < 600) {
            return `<img${p1}width="${currentWidth}"${p3}>`;
        }
        // If the current width is 600 or more, set it to 600
        return `<img${p1}width="600"${p3}>`;
    }



    const handleDocxDownload = async () => {
        // const contentState = convertFromRaw(JSON.parse(documentData.onlyDocumentContent));
        // const contentHTML = stateToHTML(contentState);
        let fileName = checkedTempName.slice(0, -5);

        let htmlString = `<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <title>${fileName}</title>
        </head>
        <body>
            <h3>${fileName}</h3>
            <hr/>
            ${editor.current.value}
        </body>
        </html>`;

        htmlString = htmlString.replace(/<img([^>]*)width="(\d+)"([^>]*)>/g, updateImgWidth);


        htmlString = htmlString.replace(/<img((?!width)[^>]*)>/g, '<img$1 width="600">');

        asBlob(htmlString).then(docx => {
            saveAs(docx, `${fileName}.docx`);
            setShowDownloadModal(false) // Close the modal after the download
        });
    };


    const fetchTemplate1 = async () => {
        let data = {
            path: "ProposalTemplates/"
        }
        try {
            const response = await getS3Data(data)
            if (response.status == 200) {
                console.log("response of full proposal", response)
                setFullProposal(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }




    //   const handletemplatePreview = async (template, folderPath) => {
    //     try {
    //       let fileExtension = template?.split('.').pop().toLowerCase();
    //       const data = {
    //         filename: template,
    //         folderPath,
    //       };

    //       console.log("check2", data);
    //       const docxContent = await getCoverPages(data);
    //       // alert("hit")
    //       console.log("fetchDataFile", docxContent);
    //       console.log(fileExtension, "docFormat");

    //       // Assuming docxContent is a Blob with application/octet-stream
    //       const blob = new Blob([docxContent], { type: "application/octet-stream" });
    //       console.log(blob, "blob");

    //       // Convert Blob to text to check its content
    //       const textContent = await blob.text();
    //       console.log(textContent, "textContent");

    //       if (fileExtension === "html") {
    //         // Create a new Blob with the correct MIME type for HTML
    //         const htmlBlob = new Blob([textContent], { type: "text/html" });
    //         const url = URL.createObjectURL(htmlBlob);
    //         console.log(url, "url");
    //         // Update state to set the file URL and open the preview
    //         setFileURL(url);
    //          setPopupOpen(true);

    //       } else {
    //         // Handle other types of content or file extensions if needed
    //         const url = URL.createObjectURL(blob);
    //         console.log(url, "url");
    //         setFileURL(url);
    //          setPopupOpen(true);
    //       }

    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }


    const handletemplatePreview = async (template, folderPath) => {
        try {
            // Check if the template is "Blank Template"
            if (template === "Blank Template") {
                // Handle the "Blank Template" case by showing a blank preview
                console.log("Showing blank template");

                // Create a blank HTML page as the content
                const blankHtmlContent = `
                    <html>
                    <head>
                        <title>Blank Template</title>
                    </head>
                    <body style="display: flex; justify-content: center; align-items: center; height: 100vh; background-color: #f0f0f0;">
                        <h1>This is a blank template</h1>
                    </body>
                    </html>
                `;

                // Create a Blob with the blank HTML content
                const blankHtmlBlob = new Blob([blankHtmlContent], { type: "text/html" });
                const blankUrl = URL.createObjectURL(blankHtmlBlob);

                // Update state to set the blank file URL and open the preview
                setFileURL(blankUrl);
                setPopupOpen(true);

                // Exit the function early to avoid fetching or downloading anything
                return;
            }

            // Handle other cases when the template is not "Blank Template"
            let fileExtension = template?.split('.').pop().toLowerCase();
            let baseFilename = template.substring(0, template.lastIndexOf('.')); // Get the part before .html

            // Add _Preview before .html
            const data = {
                filename: `${baseFilename}_Preview.html`,
                folderPath,
            };

            let docxContent;
            if (template !== "Blank Template") {
                console.log("Fetching file data:", data);
                docxContent = await getCoverPages(data);  // Assuming getCoverPages fetches the file content
            }

            console.log("File content:", docxContent);
            console.log("File extension:", fileExtension);

            // Convert fetched content to Blob and handle different file types
            const blob = new Blob([docxContent], { type: "application/octet-stream" });

            // Convert Blob to text to check its content
            let textContent = await blob.text();

            if (fileExtension === "html") {
                // Process HTML content, for example, replacing certain tags
                // You can modify this as needed to replace or adjust the content
                const htmlBlob = new Blob([textContent], { type: "text/html" });
                const url = URL.createObjectURL(htmlBlob);
                console.log("Generated HTML file URL:", url);

                // Set file URL for HTML content
                setFileURL(url);
                setPopupOpen(true);
            } else {
                // Handle non-HTML files (other file extensions)
                const url = URL.createObjectURL(blob);
                console.log("Generated file URL for non-HTML:", url);

                // Set file URL for non-HTML content
                setFileURL(url);
                setPopupOpen(true);
            }

        } catch (error) {
            console.error("Error in previewing the template:", error);
        }
    };


    const handleAddsection = async (filename, folderPath) => {
        try {
            // setLoading(true)

            console.log("ok")
            let docFormat = filename?.split('.').pop().toLowerCase();
            let data = {
                filename,
                folderPath
            };
            const docxContent = await getCoverPages(data);
            // console.log("check2", filename, docFormat);
            // console.log("docxContent", docxContent);
            // console.log(docFormat, "docFormat");

            const reader = new FileReader();
            reader.readAsText(new Blob([docxContent]));
            reader.onload = function (event) {
                let htmlContent = event.target.result;
                console.log("htmlContent00", htmlContent);
                parseHtmlContent(htmlContent);

            };
        }
        catch (error) {
            console.log(error, "error in html content")
        }
    }


    const handleEditTemplate = async () => {
        try {
            setEditPopup(true)
        } catch (error) {
            console.log(error, "error in template")
        }

    }
    const handleEditCloseTemplate = async () => {
        try {
            setEditPopup(false)
        } catch (error) {
            console.log(error, "error in template")
        }

    }



    const parseHtmlContent = async (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        const tocEntries = [];

        // Select all TOC entries
        const tocElements = doc.querySelectorAll('div.t');

        tocElements.forEach(element => {
            // Clean up the text content by removing hidden characters and extra spaces
            let textContent = element.textContent.replace(/\u200B/g, '').trim();
            textContent = textContent.replace(/<[^>]*>/g, ''); // Remove all HTML tags
            textContent = textContent.replace(/\s\s+/g, ' '); // Ensure single spacing

            // console.log("textContent", textContent); // Log text content for debugging

            // Adjusted TOC pattern to capture all entries
            const tocPattern = /^(.*?)\s*\.{5,}\s*(\d+)\s*$|^(.*?)\s*…{5,}\s*(\d+)\s*$/;
            const match = textContent.match(tocPattern);

            if (match) {
                const sectionTitle = match[1] ? match[1].trim() : match[3].trim();
                const pageNumber = match[2] ? parseInt(match[2], 10) : parseInt(match[4], 10);
                tocEntries.push({ section: sectionTitle, page: pageNumber });
            }
        });

        console.log("TOC Entries:", tocEntries);
        setTableHeadings(tocEntries)
        // Now tocEntries contains the extracted Table of Contents
        extractSections(htmlContent, tocEntries);
    };

    const extractSections = async (htmlContent, tocEntries) => {
        try {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlContent, 'text/html');

            const styles = Array.from(doc.querySelectorAll('style')).map(style => style.outerHTML).join('');
            const inlineStyles = Array.from(doc.querySelectorAll('[style]')).map(el => el.getAttribute('style')).join(' ');

            const structuredContent = [];

            // Find the first Table of Contents page number
            const firstTocPageNumber = tocEntries.length > 0 ? tocEntries[0].page : null;

            if (firstTocPageNumber) {
                const tocPageDivs = [];
                let currentPageNumber = 1;

                while (currentPageNumber < firstTocPageNumber) {
                    console.log(`Looking for page number: ${currentPageNumber}`);

                    // Find the div with the data-page-no attribute
                    const pageDiv = Array.from(doc.querySelectorAll(`div[data-page-no]`)).find(div => {
                        const pageNumberDiv = div.querySelector('div.c > div.t');
                        if (pageNumberDiv) {
                            const pageNumberText = pageNumberDiv.textContent.trim().split(/\s+/)[0];
                            return pageNumberText === currentPageNumber.toString();
                        }
                        return false;
                    });

                    if (!pageDiv) {
                        console.error(`Page number ${currentPageNumber} not found in HTML content.`);
                        break;
                    }

                    tocPageDivs.push(pageDiv.outerHTML);
                    currentPageNumber++;
                }

                // Construct the Table of Contents content
                const tocPageContent = `
                    <!DOCTYPE html>
                    <html xmlns="http://www.w3.org/1999/xhtml">
                    <head>
                        <meta charset="utf-8"/>
                        <meta name="generator" content="pdf2htmlEX"/>
                        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>
                        <style type="text/css">
                            ${styles}
                        </style>
                    </head>
                    <body>
                        <div id="page-container">
                            ${tocPageDivs.join('').trim()}
                        </div>
                    </body>
                    </html>
                `;
                structuredContent.push({
                    title: "Table of Contents",
                    content: tocPageContent
                });
            } else {
                console.error('Table of Contents page not found.');
                setLoading(false)
            }


            tocEntries.forEach((toc, index) => {
                const startPageNumber = toc.page;
                const endPageNumber = (index < tocEntries.length - 1) ? tocEntries[index + 1].page : null;

                const pageDivs = [];
                let currentPageNumber = startPageNumber;

                while (true) {
                    console.log(`Looking for page number: ${currentPageNumber}`);

                    // Find the div with the data-page-no attribute
                    const pageDiv = Array.from(doc.querySelectorAll(`div[data-page-no]`)).find(div => {
                        const pageNumberDiv = div.querySelector('div.c > div.t');
                        if (pageNumberDiv) {
                            // console.log("pageNumberText , "pageNumberDiv.textContent:",pageNumberDiv.textContent , "pageNumberDiv:",pageNumberDiv)
                            const pageNumberText = pageNumberDiv.textContent.trim().split(/\s+/)[0];
                            return pageNumberText === currentPageNumber.toString();
                        }
                        return false;
                    });

                    if (!pageDiv) {
                        console.error(`Page number ${currentPageNumber} not found in HTML content.`);
                        break;
                    }
                    // console.log(`Found page div: ${pageDiv.outerHTML}`);
                    pageDivs.push(pageDiv.outerHTML);

                    if (endPageNumber && currentPageNumber + 1 === endPageNumber) {
                        break;
                    }
                    currentPageNumber++;
                }

                // Construct the section content
                const sectionContent = `
                <!DOCTYPE html>
                <html xmlns="http://www.w3.org/1999/xhtml">
                <head>
                    <meta charset="utf-8"/>
                    <meta name="generator" content="pdf2htmlEX"/>
                    <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>
                    <style type="text/css">
                        ${styles}
                       
                    </style>
                </head>
                <body>
                    <div id="page-container">
                        ${pageDivs.join('').trim()} <!-- Insert the extracted page content -->
                    </div>
                </body>
                </html>
            `;
                // ${inlineStyles} /* Include inline styles */
                structuredContent.push({
                    title: toc.section,
                    content: sectionContent
                });
            });
            setComePareObject(structuredContent)
            console.log("Structured Content:", structuredContent);
            setOpenResourceLinkModal(true)


        } catch (error) {
            console.log("error", error)
        } finally {
            setLoading(false)
        }
    }

    const fetchAssetData = async () => {
        try {
            const response = await getasset(assetid);
            console.log("setAssetData", response)
            setAssetData(response.data);
            const assetRespons = await getAssetSectionData();
            if (assetRespons) {
                console.log("assetRespons", assetRespons)
                getAllSectionData(assetRespons)
                setAssetSectionDataValidation(assetRespons)
            }
        } catch (err) {
            showToast('Error.errorOccured')
        }
    };

    const getAssetSectionData = async () => {
        try {
            const response = await getAssetsection(assetData?.opportunityid, "proposal", assetid);

            if (response) {
                console.log("response of asssetsection ", response);

                const manualSections = [];
                const aiGeneratedSections = [];

                // Iterate through the response data
                const abc = response?.data?.forEach(section => {
                    console.log(section, "section_");  // This should now log correctly

                    if (section.sectionType === "Manual") {
                        manualSections.push(section);
                    } else if (section.sectionType === "Ai-generated") {
                        aiGeneratedSections.push(section);
                    }
                });

                // Set state after processing all sections
                setManualSections(manualSections);
                setAiGeneratedSections(aiGeneratedSections);

                console.log(manualSections, "manualSections");
                console.log(aiGeneratedSections, "aiGeneratedSections");

                return response.data;
            } else {
                console.log("error in response");
            }
        } catch (e) {
            console.log(e, "error");
        }
    };




    useEffect(() => {
        if (opportunity !== null) {
            console.log("MNN", opportunity)
            const resourceLinksPath = opportunity?.resourceLinksPath;
            const resourceLinks = opportunity?.resourceLinks;
            const combinedResources = [];
            resourceLinksPath.forEach((pathObj, index) => {
                const { path, linkname } = pathObj;
                const url = resourceLinks[index];
                const combinedObject = {
                    path,
                    linkname,
                    url
                };
                combinedResources.push(combinedObject);
            });
            console.log("76543   ", combinedResources);
            setDocumentData(combinedResources)

        }
    }, [opportunity])


    const getOpportunityData = async () => {
        console.log("oppopopopopo", opportunityId)
        try {
            const response = await getopportunities(opportunityId)
            console.log("getOpportunityData", response)
            if (response) {
                console.log("Descrption response is ", response)
                setOpportunity(response.data)

            }

        } catch (e) {
            console.log("error in getting description ", e)
        }
    }
    useEffect(() => {
        fetchAssetData()
        getOpportunityData()
    }, [])


    const handleSelectAttachment = (attachmentName) => {
        const attachmentUrl = attachmentName;
        const isSelected = resourceLinks.includes(attachmentUrl);

        if (isSelected) {
            const updatedLinks = resourceLinks.filter((link) => link !== attachmentUrl);
            setResourceLink(updatedLinks);
        } else {
            if (resourceLinks.length < 5) {
                setResourceLink([...resourceLinks, attachmentUrl]);
            } else {
                showToast('Proposal.errDocLimit')
            }
        }
    };


    const predefinedSections = [
        "executive summary",
        "needs assessment",
        "goals and objectives",
        "expected result",
        "desired features",
        "maintenance and support",
        "project deliverables",
        "training plan",
        "technical approach",
        "project assumption",
        "about us"
    ];
    const [tableHeading, setTableHeadings] = useState([])
    const [compareObject, setComePareObject] = useState()







    // const splitContentIntoPages = (content, maxHeight, firstPagePadding, subsequentPagePadding) => {
    //     const tempDiv = document.createElement('div');
    //     tempDiv.style.position = 'absolute';
    //     tempDiv.style.visibility = 'hidden';
    //     tempDiv.style.top = '0';
    //     tempDiv.style.left = '0';
    //     tempDiv.style.width = '803px'; // Fixed width to match your page layout
    //     tempDiv.style.padding = `0px 0px`;
    //     tempDiv.style.boxSizing = 'border-box';
    //     tempDiv.style.whiteSpace = 'pre-wrap';
    //     tempDiv.style.lineHeight = '1.5'; // Consistent line-height
    //     tempDiv.style.height = `${maxHeight}px`;
    //     document.body.appendChild(tempDiv);
        
    //     let pages = [];
    //     let currentPageContent = '';
        
    //     const words = content?.replace(/\n/g, '<br>').split(/(<br>|\s+)/).filter(Boolean);
    //     if(!words){
    //         return pages
    //     }
    //     words.forEach((word) => {
    //         const newContent =  currentPageContent + word + ""
            
    //         // Update the tempDiv content to calculate the height with the new word added
    //         tempDiv.innerHTML = newContent;
    //         const newHeight = tempDiv.scrollHeight; // Get the height of content
            
    //         if (newHeight > maxHeight) {
    //             // If it overflows, save the current page and start a new one
    //             pages.push(currentPageContent.trim());
    //             currentPageContent = word; // Start new page with the word that caused the overflow
    //         } else {
    //             currentPageContent = newContent; // Continue adding words to the current page
    //         }
    //     });
        
    //     if (currentPageContent) {
    //         pages.push(currentPageContent.trim()); // Push the last page's content
    //     }
    
    //     document.body.removeChild(tempDiv); // Clean up the temporary div
    
    //     return pages;
    // };



    

    const splitContentIntoPages = (content, maxHeight, firstPagePadding, subsequentPagePadding) => {
        const tempDiv = document.createElement('div');
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.style.top = '0';
        tempDiv.style.left = '0';
        tempDiv.style.width = '803px'; // Fixed width to match your page layout
        tempDiv.style.padding = `0px 0px`;
        tempDiv.style.boxSizing = 'border-box';
        tempDiv.style.whiteSpace = 'pre-wrap';
        tempDiv.style.lineHeight = '1.5'; // Consistent line-height
        tempDiv.style.height = `${maxHeight}px`;
        document.body.appendChild(tempDiv);
        
        let pages = [];
        let currentPageContent = '';
        
        // Split content into words, including handling line breaks and spaces
        const words = content?.replace(/\n/g, '<br>').split(/(<br>|\s+)/).filter(Boolean);
        if (!words) {
            return pages;
        }
        
    
        words.forEach((word) => {
            // Ensure no leading/trailing spaces or extra line breaks are added to new pages
            const trimmedWord = word.trim();
            if (trimmedWord === '<br>' && currentPageContent === '') {
                return; // Skip this iteration
            }
            const newContent = currentPageContent + trimmedWord + " ";
            
            // Update the tempDiv content to calculate the height with the new word added
            tempDiv.innerHTML = newContent;
            const newHeight = tempDiv.scrollHeight; // Get the height of content
            
            if (newHeight > maxHeight) {
                // If it overflows, save the current page and start a new one
                pages.push(currentPageContent);
                currentPageContent = trimmedWord // Start new page with the word that caused the overflow
            } else {
                currentPageContent = newContent; // Continue adding words to the current page
            }
        });
        
        if (currentPageContent) {
            pages.push(currentPageContent.trim()); // Push the last page's content
        }
    
        document.body.removeChild(tempDiv); // Clean up the temporary div
    
        return pages;
    };
    

  
    



    const handleAi = async (headings, extractedText) => {
        try {
            console.log("called00", extractedText)
            const headingsForImprovement = headings.filter(heading => predefinedSections.includes(heading));
            // Call improveInput only if there are headings for improvement
            if (headingsForImprovement.length > 0) {
                console.log("called99")
                const sectionResponse = await improveInput(extractedText, headingsForImprovement);
                console.log("sectionResponse", sectionResponse)
                return sectionResponse
            }
        } catch (error) {
            console.log("error ", error)
        }
    }


    const getFullProposalCurrentPage = () => {
        const startIndex = (fullProposalCurrentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return fullProposal.slice(startIndex, endIndex);
    };

    const handleFullProposalChange = (event, page) => {
        setFullProposalCurrentPage(page);
    };

    const getMyTemplatesCurrentPage = () => {
        const startIndex = (myTemplatesCurrentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return templateData.slice(startIndex, endIndex);
    };

    const handleMyTemplatesChange = (event, page) => {
        setMyTemplatesCurrentPage(page);
    };

    useEffect(() => {
        if (departMentSector) {
            const defaultSector = sectorData.find(
                (option) => option.value === departMentSector
            );
            setSelectedSectors(defaultSector || null);
        }
    }, [departMentSector]);

    const handleSectorFieldChange = (selectedOption) => {
        setSelectedSectors(selectedOption);
        setDepartmentSector(selectedOption?.value || "");
    };


    const handleSelectFieldChange = (selectedOptions) => {
        if (selectedOptions.some(option => option.value === 'select-all')) {
            if (selectedFields.length === AiData.length - 1) {
                setSelectedFields([]);
            } else {
                setSelectedFields(AiData.filter(option => option.value !== 'select-all'));
            }
        } else {
            setSelectedFields(selectedOptions || []);
        }
    };

    const createSections = async () => {
        try {
            console.log("selectedTemplate", selectedTemplate)
            setLoading(true)
            let data = {
                filename: selectedTemplate,
                folderPath: "ProposalTemplates"
            };
            let docxContent;
            // Fetch the cover pages content
            // alert(selectedTemplate);
            if (selectedTemplate !== "Blank Template") {
                docxContent = await getCoverPages(data);
            }
            let sectionResponse = null;

            let assetSectionData = null;
            console.log(selectedFields, "selectedFields")
            const filteredArray = selectedFields
                .filter(item => item.isDisabled === false) // Filter out objects where isDisabled is false
                .map(item => item.value);
            console.log("aiGeneratedSections", aiGeneratedSections)
            console.log("filteredArray", filteredArray)
            const concatenatedContent = aiGeneratedSections.map(section => section.htmlContent).join(' ');
            console.log(concatenatedContent, "concatenatedContent");
            sectionResponse = await improveSectionContent(concatenatedContent, filteredArray);

            const allSections = [...sectionResponse, ...assetSectionTemplateData];
            console.log(allSections, "allSections");




            // Convert the docxContent (binary) to text using FileReader
            const reader = new FileReader();
            reader.readAsText(new Blob([docxContent]));

            // Example usage
            reader.onload = function (event) {
                // Step 1: Parse the loaded HTML content
                let htmlContent = event.target.result;
                console.log("Original HTML Content:", htmlContent);
                const data = {
                    style: htmlContent,
                    assetId: assetid
                }
                const themeResponse = addTemplateTheme(data)

                // Step 2: Use DOMParser to convert the loaded HTML string into a document object
                const parser = new DOMParser();
                const doc = parser.parseFromString(htmlContent, 'text/html');

                // Step 3: Extract all 'style' elements from the document for reuse in the final output
                const styles = Array.from(doc.querySelectorAll('style')).map(style => style.outerHTML).join('');
                localStorage.setItem("TemplateStyle", htmlContent)
                // Step 4: Define constants for height and padding
                // const MAX_HEIGHT = 714;
                const MAX_HEIGHT = 1060;
                const FIRST_PAGE_PADDING = { horizontal: 45, vertical: 130 };
                const SUBSEQUENT_PAGE_PADDING = { horizontal: 45, vertical: 130 };

                // Step 5: Loop through each section to apply changes
                allSections.forEach((obj, objIndex) => {
                    // Split the content of each section into multiple pages based on height constraints
                    const pages = splitContentIntoPages(obj.Content, MAX_HEIGHT, FIRST_PAGE_PADDING, SUBSEQUENT_PAGE_PADDING);
                    console.log("splitContentIntoPages",obj.Heading,"______",obj.Content,)
                    console.log("splitContentIntoPages  response",pages)

                    let updatedContent = ''; // This will store the final HTML content for this section

                    // Step 6: Process each page of content individually
                    pages.forEach((pageContent, pageIndex) => {
                        // Locate the 'pf' div in the parsed document (template structure)
                        const pf1Div = doc.querySelector('div.pf');

                        if (pf1Div) {
                            // Locate the 'pc' div and the 'img' tag inside it (logo or image)
                            const pcDiv = pf1Div.querySelector('div.pc');
                            if (pcDiv) {
                                const imgTag = pcDiv.querySelector('img');
                                if (imgTag) {
                                    // Replace heading after the image
                                    let sibling = imgTag.nextElementSibling;
                                    let firstTextFound = false; // Track if the first non-empty text sibling is found

                                    // Step 7: Traverse the siblings of the image tag and replace their content with the section heading
                                    while (sibling && !firstTextFound) {
                                        if (sibling.textContent.trim()) {
                                            // Clear the content of all child nodes in this sibling
                                            sibling.querySelectorAll('*').forEach(node => {
                                                if (node.textContent.trim()) {
                                                    node.textContent = ''; // Clear existing text
                                                }
                                            });

                                            // Insert the section heading into the first cleared child node
                                            sibling.querySelectorAll('*').forEach(node => {
                                                if (node.textContent === '') {
                                                    node.textContent = `${obj.Heading}`; // Insert custom heading
                                                    firstTextFound = true;
                                                }
                                            });
                                        }
                                        // Move to the next sibling if no text content is found
                                        sibling = sibling.nextElementSibling;
                                    }

                                    // If the heading was successfully replaced, proceed to create the HTML structure for the pages
                                    if (firstTextFound) {
                                        let pageDivContent = '';

                                        if (pageIndex === 0) {
                                            // For the first page, reuse the existing 'pf' div and append the editable area
                                            pageDivContent = `
                                                <div id="pf1" class="pf w0 h0" data-page-no="1">
                                                    ${pf1Div.innerHTML}
                                                    <div class="editable-area" style="top:140px; left: ${FIRST_PAGE_PADDING.horizontal}px; right: ${FIRST_PAGE_PADDING.horizontal}px; bottom: 20px ;">
                                                        <div>${pageContent}</div>
                                                    </div>
                                                </div>`;
                                        } else {
                                            // For subsequent pages, generate new 'pf' divs with updated content
                                            pageDivContent = `
                                                  <div id="pf${pageIndex + 1}" class="pf w0 h0" data-page-no="${pageIndex + 1}">
                                                    ${pf1Div.innerHTML}
                                                    <div class="editable-area" style="top:140px; left: ${SUBSEQUENT_PAGE_PADDING.horizontal}px; right: ${SUBSEQUENT_PAGE_PADDING.horizontal}px; bottom: 20px ;">
                                                      <div>${pageContent}</div>
                                                    </div>
                                                </div>`;
                                        }

                                        updatedContent += pageDivContent; // Append current page content to the updated content

                                        // Step 8: Wrap the entire updated content in HTML structure with head, styles, and body
                                        let finalDocumentContent = `
                                            <!DOCTYPE html>
                                            <html xmlns="http://www.w3.org/1999/xhtml">
                                            <head>
                                                <meta charset="utf-8"/>
                                                <meta name="generator" content="pdf2htmlEX"/>
                                                <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>
                                                <style type="text/css">
                                                    ${styles}
                                                </style>
                                                <style>
                                                    .editable-area {
                                                        position: absolute;
                                                        background: rgba(255, 255, 255, 0);
                                                        z-index: 1;
                                                        white-space: pre-wrap;
                                                        overflow: hidden;
                                                        box-sizing: border-box;
                                                        height:1085px

                                                    }
                                                </style>
                                            </head>
                                            <body>
                                                <div id="page-container">
                                                ${updatedContent}
                                                </div>
                                            </body>
                                            </html>
                                        `;

                                        // Update the section's content with the newly generated HTML
                                        obj.Content = finalDocumentContent;

                                        console.log("Updated section content with pagination:", obj.Content);
                                    }
                                } else {
                                    console.error("No 'img' tag found inside the 'pc' div.");
                                }
                            } else {
                                console.error("No 'pc' class found inside the 'pf1' div.");
                            }
                        } else {
                            console.error("No 'pf1' class found in the document.");
                        }
                    });
                });


            };






            console.log("Improved sections:", allSections);
            if (allSections && Array.isArray(allSections)) {
                for (const section of allSections) {
                    const isHeadingPresent = assetSectionDataValidation.some(validationItem => validationItem.title === section.Heading);
                    // If the heading is not found, call AddSectionData2
                    if (!isHeadingPresent) {
                        await AddSectionData2(section.Heading, " ");
                    }
                }

                try {
                    assetSectionData = await getAssetsection(
                        rfmManagementData.ProposalData
                            .id, assetType, rfmManagementData.ProposalData
                        .assetid
                    );
                } catch (error) {
                    console.error("Error getting asset section data:", error);
                    // Handle error if needed
                }
                console.log("assetSectionData", assetSectionData)
                if (allSections && assetSectionData && assetSectionData.data) {
                    for (const section of allSections) {
                        const newTitle = section.Heading.toLowerCase();
                    
                        // Use filter to find all matching sections
                        const matchingSections = assetSectionData.data.filter(
                            assetSection => assetSection.title.toLowerCase() === newTitle
                        );
                    
                        // Process each matching section
                        for (const matchedSection of matchingSections) {
                            console.log("Adding HTML content for section:", section.Heading);
                            console.log("matchedSection", matchedSection);
                    
                            if (matchedSection) {
                                const formData = new FormData();
                                const blob = new Blob([section?.Content], { type: "text/html" });
                                const sanitizedTitle = matchedSection.title.replace("/", ''); // Ensure the title is safe for filenames
                    
                                formData.append("filename", blob, sanitizedTitle); // Append content as file with generated filename
                                formData.append("orgName", userInfo.organizationName);
                                formData.append("assetid", matchedSection.assetId);
                                formData.append("assetsectionId", matchedSection._id); // Use assetsectionId from matchedSection
                                formData.append("htmlContent", section?.Content); // Use Content from the first API response
                                formData.append("userId", userInfo._id);
                                formData.append("orgId", matchedSection.orgId);
                                formData.append("assetType", "proposal");
                    
                                console.log("Proceeding to next function");
                    
                                try {
                                    await addContentInAutoSection(formData); // Upload the content
                    
                                } catch (err) {
                                    console.log(`Error uploading section ${section.Heading}:`, err);
                                    toast.error("Error occurred while adding section.");
                                }
                            }
                        }
                    }
                    history.push("/proposalWriting")
                    console.log("hiishsih")
                }
                console.log("All sections improved successfully.");
            } else {
                console.log("Invalid section response format or empty response.");
            }
        } catch (error) {
            console.log(error, "Error in creating sections.")
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        console.log("rfmManagementData", rfmManagementData)
    }, [rfmManagementData])

    const AddSectionData2 = async (heading, description) => {
        try {

            const response = await addAssetsection(rfmManagementData.ProposalData.assetid, description, heading, "rfp", "Ai-generated", orgId, userInfo._id)
            if (response.status === 200) {
                console.log(`Section "${heading}" added successfully.`);
                // Handle success if needed
            } else {
                console.log(`Failed to add section "${heading}".`);
                // Handle failure if needed
            }
        } catch (error) {
            console.log("Error adding section:", error);
            // Handle error if needed
        }
    }

    const addContentInAutoSection = async (formData1) => {

        try {

            const response = await axios.post(
                `https://api.contractpro360.com/api/newbucket/assetsectionuploads/${rfmManagementData.ProposalData.id}`,
                formData1,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status === 200) {
                // console.log("upload response", response)
                // toast.success("uploaded successfully");
                return true
            }
        } catch (err) {
            console.log(err);
        }
    };



    const handleClearAllManualSection = () => {
        setSelectedFields([])
        setSelectedSectors(null)
    }

    const handleFileChange = async (e) => {

        const file = e.target.files[0];
        if (file) {
            setIspdfFile(file);
        }
        console.log("Selected PDF File Data", file)
    };

    async function sectionCreation() {
        setLoading(true)
        if (selectedFields.length == 0) {
            showToast('Proposal.oneSec')
            return;
        }
        const selectedHeadings = selectedFields?.map(data => data.value.toLowerCase());
        const newHeadings = selectedHeadings?.filter(heading => !predefinedSections.includes(heading));
        console.log("New headings to add:", newHeadings);


        for (const heading of newHeadings) {
            await AddSectionData2(heading, '');

        }
        const headingsForImprovement = selectedHeadings.filter(heading => predefinedSections.includes(heading));
        if (headingsForImprovement.length > 0) {
            console.log("headingsForImprovement", headingsForImprovement)
            try {

                if (!ispdfFile) {
                    showToast('Warning.PdfUpload')
                    setLoading(false);
                    return;
                }

                // Prepare FormData
                const formData = new FormData();
                formData.append('file', ispdfFile);

                // Axios configuration
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };

                // Send FormData to backend API
                const response = await axios.post('https://api.contractpro360.com/api/newbucket/extractFileContent', formData, axiosConfig);
                console.log('Response from backend:', response.data);
                if (!response || response.length === 0) {
                    showToast('Proposal.pdfExtraction')
                    return;
                }

                // console.log("12345ty   ", response)
                // console.log("12345tyk   ", response.data)
                // console.log("12345tyz   ", response.data.data)
                // console.log("12345tyzl   ", response.data.data.data)
                const extractedText = response.data.data.data.replace(/[\n\r]+/g, ' ');;


                // console.log("bjkkm   ", extractedText)

                // setLoading(false)

                aiResponce(extractedText)
            }
            catch (error) {
                console.log(error)
                showToast('Error.somethingFailed')
                setLoading(false)


            }
        } else {
            setLoading(false)
        }
    }







    async function aiResponce(extractedText) {
        setLoading(true);

        let sectionResponse = null;
        let assetSectionData = null;
        let organizationName = userInfo?.organizationName
        let category = selectedSectors

        try {
            const selectedHeadings = selectedFields?.map(data => data.value.toLowerCase());

            const headingsForImprovement = selectedHeadings.filter(heading => predefinedSections.includes(heading));

            // Call improveInput only if there are headings for improvement
            if (headingsForImprovement.length > 0) {
                sectionResponse = await improveInput(extractedText, headingsForImprovement, organizationName, category);

                console.log("Improved sections:", sectionResponse);

                if (sectionResponse && Array.isArray(sectionResponse)) {
                    for (const section of sectionResponse) {

                        await AddSectionData2(section.Heading, " ");
                    }

                    try {
                        assetSectionData = await getAssetsection(
                            opportunityId, assetType, assetid
                        );
                    } catch (error) {
                        console.error("Error getting asset section data:", error);
                        // Handle error if needed
                    }
                    console.log("assetSectionData", assetSectionData)
                    if (sectionResponse && assetSectionData && assetSectionData.data) {
                        for (const section of sectionResponse) {
                            const newTitle = section.Heading.toLowerCase()
                            const matchedSection = assetSectionData.data.find(
                                assetSection => assetSection.title.toLowerCase() === newTitle
                            );
                            console.log("Adding HTML content for section:", section.Heading);
                            console.log("matchedSection", matchedSection)
                            if (matchedSection) {
                                const formData = new FormData();
                                const blob = new Blob([section?.Content], { type: "text/html" });

                                formData.append("filename", blob, matchedSection.title); // Append content as file with generated filename
                                formData.append("orgName", userInfo.organizationName);
                                formData.append("assetid", matchedSection.assetId);
                                formData.append("assetsectionId", matchedSection._id); // Use assetsectionId from matchedSection
                                formData.append("htmlContent", section.Content); // Use Content from the first API response
                                formData.append("userId", userInfo._id);
                                formData.append("orgId", matchedSection.orgId);
                                formData.append("assetType", "proposal");

                                try {
                                    console.log("goan to next function ")
                                    await addContentInAutoSection(formData);
                                } catch (err) {
                                    console.log(`Error uploading section ${section.Heading}:`, err);
                                    showToast('Proposal.errSecionAdd')
                                }
                            }
                        }
                    }
                    console.log("All sections improved successfully.");
                } else {
                    console.log("Invalid section response format or empty response.");
                }
            } else {
                console.log("No headings to improve found in predefined sections.");
            }
        } catch (error) {
            console.error("Error adding or improving sections:", error);
            showToast('Proposal.errSecionProcessed')
        }



        console.log("Asset section data:", assetSectionData);
        console.log("Section response:", sectionResponse);
        showToast('Proposal.sectionProcessed')
        setLoading(false);
    }





    // <!DOCTYPE html>
    // <html>
    // <head>
    //     <style>
    //         /* Ensure all parent elements have 100% height */


    //         /* Styling for the background image div */
    //         #background-container {
    //             background-image: url('${base64Image}');
    //             width: 100%;
    //             height: 100%; /* Set to 100% to cover the editor container */
    //             background-size: cover;
    //             background-position: center;
    //             background-repeat: no-repeat;
    //         }

    //         /* Optional styling for the text */
    //         p {
    //             color: white;
    //             padding: 10px;
    //             background-color: rgba(0, 0, 0, 0.5);
    //             font-size: 24px;
    //         }
    //     </style>
    // </head>
    // <body>
    //     <!-- Parent container for the Jodit editor -->
    //     <div id="editor-container">
    //         <!-- Background image container inside the editor -->
    //         <div id="background-container">
    //             <p>Can we do like this</p>
    //         </div>
    //     </div>
    // </body>
    // </html>


    const handleSelectTemplate = async (filename, folderPath) => {
        if (selectedTemplate === filename) {
            setSelectedTemplate(null);
        } else {
            setSelectedTemplate(filename);

            // let data = {
            //     filename,
            //     folderPath
            // };

            // // Fetch the cover pages content
            // const docxContent = await getCoverPages(data);

            // // Convert the docxContent (binary) to text using FileReader
            // const reader = new FileReader();
            // reader.readAsText(new Blob([docxContent]));

            // reader.onload = function (event) {
            //     let htmlContent = event.target.result;
            //     console.log("htmlContent", htmlContent);

            //     // Parse HTML content
            //     const parser = new DOMParser();
            //     const doc = parser.parseFromString(htmlContent, 'text/html');
            //     const styles = Array.from(doc.querySelectorAll('style')).map(style => style.outerHTML).join('');
            //     const contentArray = [
            //         { heading: "Page 1 Heading", content: "Page 1 Content... (long text)" },
            //         { heading: "Page 2 Heading", content: "Page 2 Content... (long text)" },
            //         // Add more objects as needed
            //       ];
            //     // Find the 'pf' div and nested 'img' tag
            //     const pfDiv = doc.querySelector('div.pf');
            //     if (pfDiv) {
            //         const imgTag = pfDiv.querySelector('div.pc img');

            //         if (imgTag) {
            //             const base64Image = imgTag.src; // Extract base64 image data
            //             const maxHeight = 470;
            //             const firstPagePadding = { horizontal: 50, vertical: 145 };
            //             const subsequentPagePadding = { horizontal: 50, vertical: 10 };

            //             // Assuming contentArray is defined and holds the page content data

            //             // Start creating new HTML content
            //             let finalDocumentContent = `
            //                 <!DOCTYPE html>
            //                 <html xmlns="http://www.w3.org/1999/xhtml">
            //                 <head>
            //                     <meta charset="utf-8"/>
            //                     <meta name="generator" content="pdf2htmlEX"/>
            //                     <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1"/>
            //                     <style type="text/css">
            //                         ${styles}
            //                     </style>
            //                     <style>
            //                         .editable-area {
            //                             position: absolute;
            //                             background: rgba(255, 255, 255, 0);
            //                             z-index: 1;
            //                             white-space: pre-wrap;
            //                             overflow: hidden;
            //                             box-sizing: border-box;
            //                         }
            //                         .pc {
            //                             position: relative;
            //                             height: 100vh;
            //                             width: 100%;
            //                             overflow: hidden;
            //                             box-sizing: border-box;
            //                         }
            //                         .pc img {
            //                             width: 100%;
            //                             height: 100%;
            //                             object-fit: cover;
            //                             position: absolute;
            //                             top: 0;
            //                             left: 0;
            //                         }
            //                     </style>
            //                 </head>
            //                 <body>
            //                     <div id="page-container">
            //             `;

            //             // Loop through content array to generate pages
            //             contentArray.forEach((obj, objIndex) => {
            //                 const pages = splitContentIntoPages(obj.content, maxHeight, firstPagePadding, subsequentPagePadding);
            //                 pages.forEach((pageContent, pageIndex) => {
            //                     const padding = pageIndex === 0 ? firstPagePadding : subsequentPagePadding;

            //                     // Append each page's content
            //                     finalDocumentContent += `
            //                         <div class="pf w0 h0" data-page-no="${objIndex + pageIndex + 1}">
            //                             <div class="pc">
            //                                 <img class="bi x0 y0 w1 h1" alt="" src="${base64Image}">
            //                                 <div class="editable-area" style="top: ${padding.vertical}px; left: ${padding.horizontal}px; right: ${padding.horizontal}px; bottom: ${pageIndex === 0 ? '80px' : padding.vertical}px;">
            //                                     <h1>${obj.heading}</h1>
            //                                     <p>${pageContent}</p>
            //                                 </div>
            //                             </div>
            //                         </div>
            //                     `;
            //                 });
            //             });

            //             // Close the document structure
            //             finalDocumentContent += `
            //                 </div>
            //                 </body>
            //                 </html>
            //             `;

            //             // Log the newly generated HTML
            //             console.log("New HTML Content with background image and text:", finalDocumentContent);
            //         } else {
            //             console.error("No image tag found inside the 'pc' div.");
            //         }
            //     }
            // }
        }
    }




    const handleAttachmentModal = () => {
        setOpenResourceLinkModal(true)
    }


    const getAllSectionData = async (assetSectiondata) => {
        try {
            let dummy = [];

            // Use `Promise.all` to process sections in parallel
            await Promise.all(assetSectiondata?.map(async (data) => {
                let newName = decodeURIComponent(data.documentpath[0]);
                const parts = newName.split("/");
                const FilteredName = parts.pop();

                const currentTask = data.taskAssignWith.find(
                    (t) => t.assignToEmail === cuurentUser &&
                        (t.statusofSection === "Task Performed" || t.statusofSection === "Review Inprogress")
                );
                // let keydata = currentTask?.link ? "receiver" : "owners"
                let keydata = "owners"
                // Get document content
                const docxContent = await getSectionDocument(
                    FilteredName,
                    data.orgId,
                    opportunityId,
                    assetid,
                    userid,
                    data._id,
                    keydata
                );
                console.log("docxContent", docxContent)
                // If the document has a text response, push to the dummy array
                if (docxContent.textResponse) {
                    const parsedResponse = JSON.parse(docxContent.textResponse);
                    console.log("parsedResponse", parsedResponse)
                    const documentBody = parsedResponse?.data?.documentBody;

                    // Create a temporary DOM element to parse the documentBody
                    const tempDiv = document.createElement('div');
                    tempDiv.innerHTML = documentBody;

                    // Check if the specific structure exists
                    // const pageContainer = tempDiv.querySelector('#page-container');
                    // if (pageContainer) {
                    //     const pfElement = pageContainer.querySelector('.pf.w0.h0');
                    //     if (pfElement) {
                    //         const pcElement = pfElement.querySelector('.pc');
                    //         const imgTag = pcElement ? pcElement.querySelector('img') : null;
                    //         const editableArea = pfElement.querySelector('.editable-area');

                    //         if (pcElement && imgTag && editableArea) {
                    //             // If the structure is found, take the text from the editable-area
                    //             console.log("editableText@@",editableArea.textContent )
                    //             const editableText = (editableArea.textContent || editableArea.innerText).replace(/\n/g, "<br>");
                    //             console.log("editableText",editableText)
                    //             dummy.push({ Heading: data.title, Content: editableText });
                    //         } else {
                    //             // If the structure is not found, push the original documentBody content
                    //             dummy.push({ Heading: data.title, Content: documentBody });
                    //         }
                    //     } else {
                    //         // If pfElement not found, push the original documentBody content
                    //         dummy.push({ Heading: data.title, Content: documentBody });
                    //     }
                    // }

                    const pageContainer = tempDiv.querySelector('#page-container');
                    if (pageContainer) {
                        // Select all elements with class 'pf w0 h0'
                        const pfElements = pageContainer.querySelectorAll('.pf.w0.h0');
                        let combinedEditableContent = ''; // Variable to accumulate all content

                        // Iterate over all pf elements
                        pfElements.forEach((pfElement, index) => {
                            const pcElement = pfElement.querySelector('.pc');
                            const imgTag = pcElement ? pcElement.querySelector('img') : null;
                            const editableArea = pfElement.querySelector('.editable-area');

                            if (pcElement && imgTag && editableArea) {
                                // Extract the text from the editable area
                                let editableHTML = editableArea.innerHTML.trim();

                                // Replace <br> with newlines
                                editableHTML = editableHTML.replace(/<br\s*\/?>/gi, '\n');

                                // Remove any remaining HTML tags
                                editableHTML = editableHTML.replace(/<\/?[^>]+(>|$)/g, "");

                                // Accumulate the content from each editable area
                                combinedEditableContent += editableHTML   // Add newline between sections for clarity
                            }
                        });

                        if (combinedEditableContent.trim()) {
                            // Push combined content to dummy array
                            dummy.push({ Heading: data.title, Content: combinedEditableContent.trim() });
                        } else {
                            // If no content found, push the original documentBody content
                            dummy.push({ Heading: data.title, Content: "<br> <br>" });
                        }
                    } else {
                        // If pageContainer not found, push the original documentBody content
                        dummy.push({ Heading: data.title, Content: documentBody });
                    }

                }

            }));

            console.log("section dummy", dummy);
            setAssetSectionTemplateData(dummy);

        } catch (error) {
            console.log("error in getAllSectionData", error);
        }
    };


    const handleSort = () => {
        // Sort templateData
        const sortedTemplateData = [...templateData].sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
                return isAscending ? -1 : 1;
            }
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
                return isAscending ? 1 : -1;
            }
            return 0;
        });

        setTemplateData(sortedTemplateData);

        // Sort fullProposal
        const sortedFullProposal = [...fullProposal].sort((a, b) => {
            if (a.toLowerCase() < b.toLowerCase()) {
                return isAscending ? -1 : 1;
            }
            if (a.toLowerCase() > b.toLowerCase()) {
                return isAscending ? 1 : -1;
            }
            return 0;
        });

        setFullProposal(sortedFullProposal);

        // Toggle the sort order
        setIsAscending(!isAscending);
    };

    console.log(checkedTempName, "checkedTempName")
    return (
        <>
            <div
                style={{
                    width: "100%",
                    boxshadow: " 0px 0px 10px 5px black",
                    height: "100%",
                    display: 'grid',
                    gridTemplateColumns: 'minmax(250px, 1fr)',
                }}
            >
                <Loader loading={loading} />

                {proceedClicked ? (
                    // Render your new screen here after Proceed button clicked
                    <div style={{ height: '110vh', marginRight: "10px", display: "flex", justifyContent: "center", flexDirection: "column", padding: "20px", backgroundColor: "white", ...(deviceWidth < 960 && { marginLeft: "1.2rem" }) }}>
                        {/* Buttons Row */}
                        <div style={{ display: 'flex', marginBottom: '20px', width: "75%" }}>
                            <Modal
                                open={showDownloadModal}
                                onHide={() => setShowDownloadModal(false)}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '80%', // Adjusted width
                                        maxWidth: 400,
                                        bgcolor: 'white',
                                        borderRadius: 8,
                                        boxShadow: 24,
                                        p: 2,
                                        textAlign: 'center',
                                        overflowY: 'auto', // Enable scrolling if content exceeds box height
                                    }}
                                >
                                    <div className="modal-header">
                                        <h3 id="modal-modal-title" className="modal-title">Download Options</h3>
                                        <CloseIcon onClick={() => setShowDownloadModal(false)} className="close-icon" style={{ color: 'red', cursor: 'pointer', position: 'absolute', top: 10, right: 10 }} />
                                    </div>

                                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                                        <span>Download as...</span>
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <Button onClick={downloadSinglePDF} style={{ marginRight: "30px", marginBottom: "10px", color: "white", backgroundColor: "#1976D2", }}>
                                            PDF
                                        </Button>
                                        <Button onClick={handleDocxDownload} style={{ marginBottom: "10px", color: "white", backgroundColor: "#1976D2", }}>
                                            Word
                                        </Button>
                                    </div>
                                </Box>
                            </Modal>

                        </div>

                        {/* White Background Div Row */}
                        <div style={{ boxShadow: "0 2px 4px rgba(0, 0, 0, 0.4)", borderRadius: "10px", backgroundColor: "white", width: "95%", display: 'flex', alignItems: 'center', marginBottom: '10px', display: "flex", flexDirection: "row", }}>
                            {/* Your content here */}

                            <div style={{ textAlign: "center", width: "90%" }}>
                                {/* <p><b>{checkedTempName}</b></p> */}

                                {isEditable ? (
                                    <>
                                        <TextField
                                            value={checkedTempName}
                                            onChange={handleChange}
                                            variant="outlined"
                                            size="small"
                                            style={{ marginRight: '8px' }}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleSave}
                                        // startIcon={<SaveIcon />}
                                        >
                                            Save
                                        </Button>
                                    </>
                                ) : (
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <p><b>{checkedTempName}</b></p>
                                        {showEditIcon &&
                                            <IconButton onClick={handleEdit} aria-label="edit">
                                                <EditIcon />
                                            </IconButton>
                                        }
                                    </div>
                                )}

                            </div>


                            <div style={{ display: "flex", justifyContent: "end", width: "10%" }}>
                                <Button onClick={() => handleDownload()} style={{ fontSize: "12px", borderRadius: "10px", color: "white", borderColor: "#1976D2", textTransform: "capitalize", boxShadow: "3px 3px 8px rgba(0, 0, 0, 0.2)", backgroundColor: "#1976D2", }}  >Download <span style={{ marginLeft: "8px" }}> <DownloadIcon /></span> </Button>
                            </div>

                        </div>

                        {/* Jodit Editor Row */}
                        <div style={{ display: 'flex', flexDirection: 'column', width: "95%", }}>


                            <JoditEditor
                                config={config}
                                ref={editor}
                                value={content}
                                //   onChange={}
                                onChange={() => getHtmlcontent()}
                                tabIndex={1}
                                onBlur={newContent => setContent(newContent)}
                            />


                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '30px', width: '50%' }}>
                            <div>
                                {/* <b>10000 Words limit | Total words: {wordCount}</b> */}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                    style={{ marginRight: '10px', color: "white", backgroundColor: "#1976D2", }}

                                    onClick={handleProceedBack}
                                >
                                    Back
                                </Button>
                                <Button
                                    style={{ color: "white", backgroundColor: "#1976D2", }}
                                    onClick={() => handleTemplateupdate(checkedTempName, idForFile, path)}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: windowWidth ? '1fr' : '1fr 1fr',
                            gap: '10px',
                            gridColumn: window.innerWidth <= 768 ? '1 / -1' : 'auto',
                        }}>
                            <div style={{ display: 'flex', justifyContent: windowWidth ? 'center' : 'flex-start', gridColumn: window.innerWidth <= 768 ? '1 / -1' : 'auto', alignItems: 'center', textAlign: window.innerWidth <= 768 ? 'center' : 'left' }}>
                                Please upload the file with DOCX format only.
                            </div>
                            <div style={{ display: 'flex', justifyContent: windowWidth ? 'center' : 'flex-end', gridColumn: window.innerWidth <= 768 ? '1 / -1' : 'auto', textAlign: window.innerWidth <= 768 ? 'center' : 'left' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {/* <IconButton title="Add" onClick={handleAddopenModal}>
                                        <ControlPointIcon className='templateIcons' />
                                    </IconButton> */}
                                    {/* <IconButton>
                                        <RefreshIcon className='templateIcons' />
                                    </IconButton> */}
                                    <IconButton title="Upload" onClick={() => document.getElementById('TemplateList').click()}>
                                        <UploadIcon className='templateIcons' />
                                        <input
                                            id="TemplateList"
                                            type="file"
                                            key={inputKey}
                                            onChange={FileUploadInList}
                                            style={{ display: 'none' }}
                                        />
                                    </IconButton>
                                    {/* <IconButton>
                                        <FilterAltIcon className='templateIcons' />
                                    </IconButton> */}
                                    <IconButton>
                                        {listView
                                            ? <div title='Grid View'><WindowIcon className='templateIcons' onClick={() => setListView(false)} /></div>
                                            : <div title='List View'><TableRowsIcon className='templateIcons' onClick={() => setListView(true)} /></div>}
                                    </IconButton>
                                    {/* <IconButton>
                                        <div title="Download">
                                            <DownloadIcon className='templateIcons' />
                                        </div>
                                    </IconButton> */}
                                    <IconButton>
                                        <div title="Sort">
                                            <SortByAlphaIcon className='templateIcons' onClick={() => handleSort()} />
                                        </div>
                                    </IconButton>
                                </div>
                            </div>
                            <Modal
                                open={openAddModal}
                                onClose={() => setOpenAddModal(false)}
                            >
                                <div style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    backgroundColor: 'white',
                                    padding: '20px',
                                    borderRadius: '10px',
                                    outline: 'none',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    width: '400px'
                                }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                                        <Typography variant="h5" id="summary-modal-title">
                                            Add Proposal
                                        </Typography>
                                        <IconButton onClick={() => setOpenAddModal(false)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                    <TextField
                                        label="Title"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                    <TextField
                                        label="Description"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        margin="normal"
                                    />
                                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ marginRight: '10px' }}
                                            onClick={handleProposalCreation}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => setOpenAddModal(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gridColumn: window.innerWidth <= 768 ? '1 / -1' : 'auto', }}>
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" >
                                    {/* Blank Template */}
                                    <Box
                                        style={{
                                            backgroundColor: "white",
                                            // width: windowWidth ? "180px" : "200px",
                                            // maxWidth: "200px",
                                            height: "240px",
                                            padding: "5px",
                                            borderRadius: "10px",
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                            margin: "5px"
                                        }}
                                        sx={{ minWidth: 180 }}
                                    >
                                        <React.Fragment>
                                            <CardContent>
                                                <div
                                                    style={{
                                                        height: "220px",
                                                        width: "100%",
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'start'
                                                    }}
                                                    onClick={() => handleProceed("Blank Template")}
                                                >
                                                    <div
                                                        style={{
                                                            height: "180px",
                                                            width: "100%",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}
                                                    >
                                                        <ControlPointIcon style={{ fontSize: "80px", color: "#1976D2" }} />
                                                    </div>

                                                    <div
                                                        style={{
                                                            height: "40px",
                                                            width: "100%",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            borderTop: "1px solid black"
                                                        }}
                                                    >
                                                        Blank Template
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </React.Fragment>
                                    </Box>
                                </div>
                                <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3" >
                                    <Box
                                        style={{
                                            backgroundColor: "white",
                                            // width: windowWidth ? "180px" : "200px",
                                            // maxWidth: "200px",
                                            height: "240px",
                                            padding: "5px",
                                            borderRadius: "10px",
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                            margin: "5px"
                                        }}
                                        sx={{ minWidth: 180 }}
                                    >
                                        <React.Fragment>
                                            <CardContent>
                                                <div
                                                    style={{
                                                        height: "220px",
                                                        width: "100%",
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'start'
                                                    }}
                                                    onClick={() => handleGalleryClick()}
                                                >
                                                    <div
                                                        style={{
                                                            height: "180px",
                                                            width: "100%",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center"
                                                        }}
                                                    >
                                                        <ImageIcon style={{ fontSize: "80px", color: "#1976D2" }} />
                                                    </div>

                                                    <div
                                                        style={{
                                                            height: "40px",
                                                            width: "100%",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            borderTop: "1px solid black"
                                                        }}
                                                    >
                                                        Gallery
                                                    </div>
                                                </div>
                                            </CardContent>
                                        </React.Fragment>
                                    </Box>
                                </div>
                            </div>

                            <div style={{ width: "100%", marginTop: "30px", marginBottom: "30px", height: "30px", marginLeft: windowWidth ? '10px' : 0 }}>
                                <Typography variant="h5" gutterBottom>My Templates</Typography>
                            </div>

                            {/* Mapped Templates */}
                            <div className="row" style={{ width: deviceWidth < 375 ? "107%" : "100%" }} >
                                {templateData?.length > 0 && (
                                    getMyTemplatesCurrentPage().map((template, index) => (
                                        !listView ?
                                            <div key={index} className="col-6 col-sm-6 col-md-4 col-lg-3 mb-3 " style={{ paddingLeft: deviceWidth < 375 ? "0px" : "" }}>
                                                <Box
                                                    style={{
                                                        backgroundColor: "white",
                                                        height: "240px",
                                                        padding: "5px",
                                                        borderRadius: "10px",
                                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}
                                                >

                                                    <React.Fragment>
                                                        <CardContent>
                                                            <div style={{ backgroundColor: "white", height: "150px", whiteSpace: 'normal', maxWidth: "100%", }}>
                                                                {/* <h5 style={{ fontSize: "18px" }}>{template.title}</h5> */}
                                                                <h5 style={{ fontSize: "18px" }}>{truncateFileName(template.title, 15)}</h5>

                                                                {/*  */}


                                                                <Typography variant="body2" color="textSecondary" gutterBottom style={{ fontSize: "14px" }}>
                                                                    <b>Created by: </b>{template.createdBy}
                                                                </Typography>
                                                                <Typography variant="body2" color="textSecondary" gutterBottom style={{ fontSize: "14px" }}>
                                                                    <b>Created on: </b>{formatDate(template.createdOn)}
                                                                </Typography>



                                                            </div>
                                                        </CardContent>
                                                        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 'auto', width: "98%" }}>
                                                            {/* Preview Icon */}
                                                            <IconButton sty class="icon_button_padding" title="Preview" disableRipple >
                                                                <PreviewIcon style={{ color: "green" }} onClick={() => handlePreviewClick(template.fileName, template.path)} />
                                                            </IconButton>

                                                            <IconButton class="icon_button_padding" title="Duplicate" disableRipple >
                                                                <FileCopyIcon style={{ color: "6E2D88" }} onClick={() => handleCopyTemplate(template.title, template.path)} />
                                                            </IconButton>

                                                            {/* Edit Icon */}
                                                            <IconButton class="icon_button_padding" title="Edit" disableRipple >
                                                                <BorderColorIcon onClick={() => handleProceed(template.fileName, template.title, template._id, template.path)} style={{ color: "#1976D2" }} />
                                                            </IconButton>


                                                            {/* Delete Icon */}
                                                            <IconButton class="icon_button_padding" title="Delete" disableRipple >
                                                                <DeleteIcon style={{ color: "red" }} onClick={() => handleOpen(template.title, template._id)} />

                                                                <Modal
                                                                    open={open}
                                                                    onClose={handleClose1}
                                                                    aria-labelledby="modal-modal-title"
                                                                    aria-describedby="modal-modal-description"
                                                                >
                                                                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 300, bgcolor: 'background.paper', border: '2px solid #000', p: 2 }}>
                                                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                                                            Are you sure you want to delete?
                                                                        </Typography>
                                                                        <Box sx={{ mt: 2 }}>
                                                                            <Button onClick={() => handleDeleteConfirmation(template.title, template.fileName, templateToDelete)}>OK</Button>
                                                                            <Button onClick={handleCancelClick}>Cancel</Button>
                                                                        </Box>
                                                                    </Box>
                                                                </Modal>
                                                            </IconButton>
                                                            <IconButton disableRipple class="icon_button_padding"  >
                                                                <ControlPointOutlinedIcon style={{ fontSize: '20px', color: "D7BE25" }} onClick={() => handleFormClick(template.fileName, template.title, template._id, template.path)} />
                                                            </IconButton>
                                                        </div>
                                                    </React.Fragment>
                                                </Box>
                                            </div>
                                            :
                                            <div key={index} className="col-md-12 mb-3 " style={{ paddingLeft: deviceWidth < 375 ? "0px" : "" }}>
                                                <Box
                                                    style={{
                                                        backgroundColor: "white",
                                                        height: "130px",
                                                        padding: "5px",
                                                        borderRadius: "10px",
                                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                    }}
                                                >

                                                    <React.Fragment>
                                                        <CardContent style={{ width: '70%' }}>
                                                            <div style={{ backgroundColor: "white", whiteSpace: 'normal', maxWidth: "100%", }}>
                                                                {/* <h5 style={{ fontSize: "18px" }}>{template.title}</h5> */}
                                                                <h5 style={{ fontSize: "18px" }}>{truncateFileName(template.title, 35)}</h5>

                                                                {/*  */}


                                                                <Typography variant="body2" color="textSecondary" gutterBottom style={{ fontSize: "14px" }}>
                                                                    <b>Created by: </b>{template.createdBy}
                                                                </Typography>
                                                                <Typography variant="body2" color="textSecondary" gutterBottom style={{ fontSize: "14px" }}>
                                                                    <b>Created on: </b>{formatDate(template.createdOn)}
                                                                </Typography>



                                                            </div>
                                                        </CardContent>
                                                        <div style={{ display: 'flex', justifyContent: 'space-around', width: "30%" }}>
                                                            {/* Preview Icon */}
                                                            <IconButton sty class="icon_button_padding" title="Preview" disableRipple >
                                                                <PreviewIcon style={{ color: "green" }} onClick={() => handlePreviewClick(template.fileName, template.path)} />
                                                            </IconButton>

                                                            <IconButton class="icon_button_padding" title="Duplicate" disableRipple >
                                                                <FileCopyIcon style={{ color: "6E2D88" }} onClick={() => handleCopyTemplate(template.title, template.path)} />
                                                            </IconButton>

                                                            {/* Edit Icon */}
                                                            <IconButton class="icon_button_padding" title="Edit" disableRipple >
                                                                <BorderColorIcon onClick={() => handleProceed(template.fileName, template.title, template._id, template.path)} style={{ color: "#1976D2" }} />
                                                            </IconButton>


                                                            {/* Delete Icon */}
                                                            <IconButton class="icon_button_padding" title="Delete" disableRipple >
                                                                <DeleteIcon style={{ color: "red" }} onClick={() => handleOpen(template.title, template._id)} />

                                                                <Modal
                                                                    open={open}
                                                                    onClose={handleClose1}
                                                                    aria-labelledby="modal-modal-title"
                                                                    aria-describedby="modal-modal-description"
                                                                >
                                                                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 300, bgcolor: 'background.paper', border: '2px solid #000', p: 2 }}>
                                                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                                                            Are you sure you want to delete?
                                                                        </Typography>
                                                                        <Box sx={{ mt: 2 }}>
                                                                            <Button onClick={() => handleDeleteConfirmation(template.title, template.fileName, templateToDelete)}>OK</Button>
                                                                            <Button onClick={handleCancelClick}>Cancel</Button>
                                                                        </Box>
                                                                    </Box>
                                                                </Modal>
                                                            </IconButton>
                                                            <IconButton disableRipple class="icon_button_padding"  >
                                                                <ControlPointOutlinedIcon style={{ fontSize: '20px', color: "D7BE25" }} onClick={() => handleFormClick(template.fileName, template.title, template._id, template.path)} />
                                                            </IconButton>
                                                        </div>
                                                    </React.Fragment>
                                                </Box>
                                            </div>

                                    ))
                                )}
                                {templateData?.length > itemsPerPage && (

                                    <div className="pagination-container" style={{ width: "100%", marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                                        <Pagination
                                            count={Math.ceil(templateData.length / itemsPerPage)}
                                            page={myTemplatesCurrentPage}
                                            onChange={handleMyTemplatesChange}
                                            color="primary"
                                            size="large"
                                            showFirstButton
                                            showLastButton
                                            shape="rounded"

                                        />
                                    </div>

                                )
                                }

                            </div>

                            <div>
                                <div style={{ width: "100%", marginTop: "30px", alignItems: "center", marginBottom: "30px", display: "flex", height: "30px", marginLeft: windowWidth ? '10px' : 0 }}>
                                    <Typography variant="h5" gutterBottom style={{ marginRight: "10px" }}> Full Proposal Template {fullProposal?.length > 0 && <><span style={{ color: "rgb(61, 131, 250)" }}>({fullProposal?.length})</span></>}</Typography>
                           

                                </div>
                                {fullProposal?.length > 0 &&
                                    <>
                                        <div className="row" style={{ width: deviceWidth < 375 ? "102%" : "100%" }}>
                                            {/* Show the "Blank Template" only on the first page and include it in a row with other templates */}
                                            {fullProposalCurrentPage === 1 && (
                                                listView ? <>
                                                    <div
                                                        className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3"
                                                        style={{ paddingLeft: deviceWidth < 375 ? "0px" : "" }}
                                                    >
                                                        <SingleTemplate
                                                            type="Template"
                                                            view={listView}
                                                            title="Blank Template"
                                                            selectedTemp={selectedTemplate}
                                                            preview={() => handletemplatePreview("Blank Template", "ProposalTemplates")}
                                                            AddClick={() => handleAddsection("Blank Template", "ProposalTemplates")}
                                                            editClick={() => handleEditTemplate("Blank Template", "ProposalTemplates")}
                                                            dynamicWidth="100%"
                                                            {...(deviceWidth < 768 && { dynamicFontSize: "1rem" })}
                                                            checkBox={() => handleSelectTemplate("Blank Template", "ProposalTemplates")}
                                                        />
                                                    </div>
                                                </> : <>
                                                    <div
                                                        className="col-6 col-sm-6 col-md-3 col-lg-3 mb-3"
                                                        style={{ paddingLeft: deviceWidth < 375 ? "0px" : "" }}
                                                    >
                                                        <SingleTemplate
                                                            type="Template"
                                                            view={listView}
                                                            title="Blank Template"
                                                            selectedTemp={selectedTemplate}
                                                            preview={() => handletemplatePreview("Blank Template", "ProposalTemplates")}
                                                            AddClick={() => handleAddsection("Blank Template", "ProposalTemplates")}
                                                            editClick={() => handleEditTemplate("Blank Template", "ProposalTemplates")}
                                                            dynamicWidth="100%"
                                                            {...(deviceWidth < 768 && { dynamicFontSize: "1rem" })}
                                                            checkBox={() => handleSelectTemplate("Blank Template", "ProposalTemplates")}
                                                        />
                                                    </div></>
                                            )}

                                            {/* Map through other templates for the current page and ensure 4 templates per row */}
                                            {getFullProposalCurrentPage().filter(template => !template.includes('_Preview')).map((template, index) => (
                                                listView ? <>
                                                    <div
                                                        className={`col-12 col-sm-12 col-md-12 col-lg-12 mb-3`}
                                                        key={index}
                                                        style={{ paddingLeft: deviceWidth < 375 ? "0px" : "" }}
                                                    >
                                                        <SingleTemplate
                                                            type="Template"
                                                            view={listView}
                                                            title={template}
                                                            selectedTemp={selectedTemplate}
                                                            preview={() => handletemplatePreview(template, "ProposalTemplates")}
                                                            AddClick={() => handleAddsection(template, "ProposalTemplates")}
                                                            editClick={() => handleEditTemplate(template, "ProposalTemplates")}
                                                            dynamicWidth="100%"
                                                            {...(deviceWidth < 768 && { dynamicFontSize: "1rem" })}
                                                            checkBox={() => handleSelectTemplate(template, "ProposalTemplates")}
                                                        />
                                                    </div>
                                                </> : <>
                                                    <div
                                                        className={`col-6 col-sm-6 col-md-3 col-lg-3 mb-3`}
                                                        key={index}
                                                        style={{ paddingLeft: deviceWidth < 375 ? "0px" : "" }}
                                                    >
                                                        <SingleTemplate
                                                            type="Template"
                                                            view={listView}
                                                            title={template}
                                                            selectedTemp={selectedTemplate}
                                                            preview={() => handletemplatePreview(template, "ProposalTemplates")}
                                                            AddClick={() => handleAddsection(template, "ProposalTemplates")}
                                                            editClick={() => handleEditTemplate(template, "ProposalTemplates")}
                                                            dynamicWidth="100%"
                                                            {...(deviceWidth < 768 && { dynamicFontSize: "1rem" })}
                                                            checkBox={() => handleSelectTemplate(template, "ProposalTemplates")}
                                                        />
                                                    </div>
                                                </>
                                            ))}
                                        </div>





                                        {fullProposal?.length > itemsPerPage && (

                                            <div className="pagination-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                                                <Pagination
                                                    count={Math.ceil((fullProposal.length-1) / itemsPerPage)}
                                                    page={fullProposalCurrentPage}
                                                    onChange={handleFullProposalChange}
                                                    color="primary"
                                                    size="large"
                                                    showFirstButton
                                                    showLastButton
                                                    shape="rounded"

                                                />
                                            </div>

                                        )
                                        }
                                    </>}

                            </div>
                        </div>
                        <div style={{ display: "flex", width: "90%", justifyContent: "flex-end", marginTop: "10px" }}>
                            <Button onClick={handleBack} style={{ color: "white", backgroundColor: "#1976D2", right: '10px' }}>Back</Button>


                        </div>

                        {popupForm && (
                            <div
                                style={{
                                    position: "fixed",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
                                    zIndex: "2",
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        width: deviceWidth < 768 ? "97%" : "75%",
                                        height: "85%",
                                        backgroundColor: "white",
                                        borderRadius: "10px",
                                        padding: deviceWidth < 768 ? "0px" : "20px",
                                        position: "absolute",
                                        top: "55%",
                                        left: deviceWidth < 768 ? "50%" : "55%",
                                        transform: "translate(-50%, -50%)",
                                        overflow: "auto",
                                    }}
                                >
                                    <div className='proposal_header_flex'>
                                        <h3 className='proposal_header_tag'>Proposal Details</h3>
                                        <LightTooltip title="Preview">
                                            <IconButton style={{ position: "absolute", right: "50px" }} onClick={HandleRefreshPlaceholder} >
                                                < VisibilityIcon style={{ color: "indigo", cursor: "pointer", }} />
                                            </IconButton>
                                        </LightTooltip>


                                        <IconButton className='proposal_close_button' onClick={() => setPopupForm(false)}>
                                            <CloseIcon className='proposal_close_button' />
                                        </IconButton>

                                    </div>
                                    <div style={{ width: "100%", display: "flex", flexDirection: deviceWidth < 960 ? "column" : "row", padding: "5px", }}>

                                        <form style={{ display: "flex", flexDirection: "column", width: deviceWidth < 960 ? "100%" : "50%", }}>
                                            {/* {(contentForScan?.match(/\[([^\]]+)]/g) || []).map((match, index) => {
                                            const labelText = match.slice(1, match.indexOf("]")); */}
                                            {fieldsToRender?.map((match, index) => {
                                               const labelsToShow = showOriginalLabels ? originalLabels[index] : match;
                                                console.log("Rendering field:", match);

                                                // Regular expression to check for <img> tag and extract the src attribute
                                                // const imgTagRegex = /<img[^>]+src="([^">]+)"[^>]*>/;

                                                // Check if match contains an <img> tag
                                                // const imgMatch = imgTagRegex.exec(match);
                                                // Existing code for other label types
                                                return (
                                                    <div key={index} className="form-group" style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <div className='sub_parentflex_label'>
                                                            <div className='Lable_inner_flex'>
                                                              

                                                                <div>
                                                                    
                                                                        <>
                                                                            <label htmlFor={match} style={{ marginBottom: "10px" }}>
                                                                                <b>{labelsToShow}:</b>
                                                                            </label>
                                                                        </>
                                                                </div>


                                                                <div >
                                                                    <FormControl className='FormControl_Tag' sx={{ m: 1, }}>
                                                                        <Select
                                                                            value={dataTypeMap[match] || ''}
                                                                            onChange={(e) => handleDataTypeChange(e, match)}
                                                                            displayEmpty
                                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                                        >
                                                                            <MenuItem value="">
                                                                                <em>None</em>
                                                                            </MenuItem>
                                                                            <MenuItem value={"Input"}>Input</MenuItem>
                                                                            <MenuItem value={"Date"}>Date</MenuItem>
                                                                            <MenuItem value={"Image"}>Image</MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                            </div>

                                                            <div >
                                                                {dataTypeMap[match] == "Input" &&
                                                                    <div>
                                                                        <TextField
                                                                            type="text"
                                                                            style={{ width: "100%" }}
                                                                            id={match}
                                                                            variant='outlined'
                                                                            name={match.toLowerCase().replace(/\s/g, "-")}
                                                                            onChange={handleInputChange}

                                                                        />
                                                                    </div>
                                                                }


                                                                {dataTypeMap[match] == "Image" &&
                                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                                        <IconButton title="Upload" htmlFor={`uploadLogo-${index}`} onClick={() => document.getElementById(`uploadLogo-${index}`).click()}>
                                                                            <UploadIcon />

                                                                            <VisuallyHiddenInput
                                                                                id={`uploadLogo-${index}`}
                                                                                type="file"
                                                                                onChange={(e) => { logoUpload(e, match) }}
                                                                            />
                                                                            {imageFileNames[match.toLowerCase().replace(/\s/g, "-")] && (
                                                                                <p style={{ fontSize: "0.9rem" }}>{imageFileNames[match.toLowerCase().replace(/\s/g, "-")]}</p>
                                                                            )}
                                                                        </IconButton>
                                                                    </div>
                                                                }

                                                                {dataTypeMap[match] === "Date" && (
                                                                    <div>
                                                                        {match === "Month Day, Year" ? (
                                                                            // Render two date inputs with headings "From" and "To"
                                                                            <div style={{ display: "block", width: "100%" }}>
                                                                                <div style={{ marginBottom: "10px" }}>
                                                                                    <label>From:</label>
                                                                                    <input
                                                                                        type="date"
                                                                                        style={{ padding: "14px", width: "100%" }}
                                                                                        value={formData["from-date"] || ''}
                                                                                        onChange={(e) => handleDatePicker(e, match, true)}
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <label>To:</label>
                                                                                    <input
                                                                                        type="date"
                                                                                        style={{ padding: "14px", width: "100%" }}
                                                                                        value={formData["to-date"] || ''}
                                                                                        onChange={(e) => handleDatePicker(e, match, false)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                                                <input
                                                                                    type="date"
                                                                                    style={{ padding: "14px", width: "100%" }}
                                                                                    value={formData[match.toLowerCase().replace(/\s/g, "-")] || ''}
                                                                                    onChange={(e) => handleDatePicker(e, match)}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                    </div>
                                                );

                                            })}





                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                {/* <Button style={{ margin: "10px" }} variant="contained" color="primary" onClick={() => setPopupForm(false)}>Close</Button> */}

                                                <Button style={{ margin: "10px" }} variant="contained" color="primary" onClick={handleSubmitClick}>Submit</Button>
                                            </div>

                                        </form>


                                        <div style={{ width: deviceWidth < 960 ? "100%" : "50%", marginTop: deviceWidth < 960 ? "15px" : "0px" }}>
                                            <JoditEditor
                                                config={config2}

                                                value={placeholder}

                                                tabIndex={1}
                                            />
                                        </div>
                                    </div>
                                    <div>

                                    </div>


                                </div>
                            </div>
                        )}


                        {isPopupOpen && (
                            <Modal
                                open={isPopupOpen}
                                onClose={handleClosePopup}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}

                            >

                                <div
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '20px',
                                        borderRadius: '10px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        width: '80%',  // Set a fixed width
                                        height: '80%', // Set a fixed height
                                        overflow: 'auto', // Hide any overflow
                                        // position: 'relative',
                                        //  zIndex: 2,
                                    }}
                                >
                                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                        <Button

                                            onClick={handleClosePopup}
                                            style={{ marginTop: '10px' }}
                                        >
                                            <CloseIcon className='.proposal_close_button' />
                                        </Button>
                                    </div>

                                    <iframe
                                        src={fileURL}
                                        id='previewIframe'
                                        title="PDF Viewer"
                                        width="100%"
                                        height="500"
                                    ></iframe>


                                </div>



                            </Modal>
                        )}


                    </>
                )}

            </div>
            <PreviewEditor
                open={isPreviewOpen}
                handlePreviewCancel={closePreview}
                content={previewContent}
                joditRef={editor}
            />


            <Modal1
                show={isDuplicateModal}
                onHide={() => setShowDuplicateModal(false)}
                centered
            // className="user-deactivate-modal"
            >
                <Modal1.Header >
                    <Modal1.Title>
                        <div class="proposal_header_flex">
                            <div className='duplicate_template_hedar_tag'>
                                {!copyButtonValidation ? <h3 className='proposal_header_tag'>Upload File</h3> : <h3 className='proposal_header_tag'>Duplicate template</h3>}
                            </div>

                            <div class='close_Button_style'>
                                <IconButton onClick={() => setShowDuplicateModal(false)}>
                                    <CloseIcon className='proposal_close_button' />
                                </IconButton>
                            </div>
                        </div>

                    </Modal1.Title>
                </Modal1.Header>
                <Modal1.Body>
                    <p className='filename_info_ptag'>File with same name already exists please enter new file name</p>
                    <TextField
                        style={{ width: "100%" }}
                        as="textarea"
                        variant='outlined'
                        value={duplicateFileName ? duplicateFileName : existingFileName}
                        onChange={(e) => handlesduplicateFileName(e.target.value)}
                    />
                </Modal1.Body>
                <Modal1.Footer>
                    <button
                        class='common_button_style_blue'
                        onClick={handleExistingFileRename}
                    >
                        Save
                    </button>
                    <button
                        class='common_button_style_red'
                        onClick={() => setShowDuplicateModal(false)}
                    >
                        Cancel
                    </button>

                </Modal1.Footer>
            </Modal1>



            <EditTemplateModal
                open={editPopup}
                close={handleEditCloseTemplate}
                sectorData={sectorData}
                selectedSector={selectedSectors}
                handleSectorFieldChange={handleSectorFieldChange}
                sectionData={assetData?.aiSections}
                handleSelectFieldChange={handleSelectFieldChange}
                selectedSection={selectedFields}
                mandatorySections={aiGeneratedSections}
                manualSections={manualSections}
                createSections={createSections}
            />

        </>
    );
};
